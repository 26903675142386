import React from 'react';
import { NavLink } from "react-router-dom";
import { FiBox, FiShoppingBag, FiDollarSign, FiSettings } from "react-icons/fi";

import { customerRouteName } from '../../data/InfoSource';

export const PanelMainMenu = ({ ecommerce, isCondensed }) => {

    const shopifyPages = [
        { title: "Órdenes", routeInfo: `/${customerRouteName}/secommerce/panel/order/all`, icon: <FiBox /> },
        { title: "Tarifa", routeInfo: `/${customerRouteName}/secommerce/panel/rates`, icon: <FiDollarSign /> },
        { title: "Tiendas", routeInfo: `/${customerRouteName}/secommerce/panel/location-list`, icon: <FiShoppingBag /> },
        { title: "Configuraciones", routeInfo: `/${customerRouteName}/secommerce/panel/settings`, icon: <FiSettings /> },
    ];

    const tiendanubePages = [
        { title: "Órdenes", routeInfo: `/${customerRouteName}/tiendanube/panel/order/all`, icon: <FiBox /> },
        { title: "Tarifa", routeInfo: `/${customerRouteName}/tiendanube/panel/rates`, icon: <FiDollarSign /> },
        { title: "Tiendas", routeInfo: `/${customerRouteName}/tiendanube/panel/location-list`, icon: <FiShoppingBag /> },
        { title: "Configuraciones", routeInfo: `/${customerRouteName}/tiendanube/panel/settings`, icon: <FiSettings /> },
    ];

    const vtexPages = [
        { title: "Órdenes", routeInfo: `/${customerRouteName}/vtex/panel/order/all`, icon: <FiBox /> },
        { title: "Tarifa", routeInfo: `/${customerRouteName}/vtex/panel/rates`, icon: <FiDollarSign /> },
        { title: "Tiendas", routeInfo: `/${customerRouteName}/vtex/panel/location-list`, icon: <FiShoppingBag /> },
        { title: "Configuraciones", routeInfo: `/${customerRouteName}/vtex/panel/settings`, icon: <FiSettings /> },
    ];

    return (
        <>
            {ecommerce === "shopify" &&
                <div className="panel-menu">
                    {shopifyPages.map((navItem, index) => (
                        <div key={index}>
                            {isCondensed ?
                                <NavLink to={navItem.routeInfo} activeClassName={`menu-selected ${ecommerce}`}>
                                    {navItem.icon}
                                </NavLink>
                                :
                                <NavLink to={navItem.routeInfo} activeClassName={`menu-selected ${ecommerce}`}>
                                    {navItem.icon} {navItem.title}
                                </NavLink>
                            }
                        </div>
                    )
                    )}
                </div>
            }
            {ecommerce === "tiendanube" &&
                <div className="panel-menu">
                    {tiendanubePages.map((navItem, index) => (
                        <NavLink to={navItem.routeInfo} activeClassName={`menu-selected ${ecommerce}`} key={index}>
                            {navItem.icon} {navItem.title}
                        </NavLink>)
                    )}
                </div>
            }
            {ecommerce === "vtex" &&
                <div className="panel-menu">
                    {vtexPages.map((navItem, index) => (
                        <NavLink to={navItem.routeInfo} activeClassName={`menu-selected ${ecommerce}`} key={index}>
                            {navItem.icon} {navItem.title}
                        </NavLink>)
                    )}
                </div>
            }
        </>
    )
}