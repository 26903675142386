import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { handleOrdersList } from '../../services/panel.service';
import { Table } from './Table';

export const GridSetter = ({ tab, ecommerce, rawData, updateTable }) => {
    const [ordersFilteredByStatus, setOrdersFilteredByStatus] = useState([]);
    const [isLoading, setisLoading] = useState(true);

    useEffect(() => {
        filterAndMapOrders(rawData);
    }, [tab])

    const filterByTabStatus = (rawData, tabSelected) => {
        if(!rawData || !tabSelected) return
        if(tabSelected === "pending"){
            return rawData.orders.filter(order => order.trackingStatus==="pending");
        }
        if(tabSelected === "created"){
            return rawData.orders.filter(order => order.trackingStatus==="ready" || order.trackingStatus==="qualifiedforpickup" || order.trackingStatus==="onroutetopickup" || order.trackingStatus==="pickingup" || order.trackingStatus==="intransit" || order.trackingStatus==="delivering" || order.trackingStatus==="availableForCollect");
        }
        if(tabSelected === "delivered"){
            return rawData.orders.filter(order => order.trackingStatus==="delivered");
        }
        if(tabSelected === "issue"){
            return rawData.orders.filter(order => order.trackingStatus==="returning" || order.trackingStatus==="returned" || order.trackingStatus==="incident" || order.trackingStatus==="requestercanceled" || order.trackingStatus==="internalcanceled" || order.trackingStatus==="pickupfailed" || order.trackingStatus==="cancelled" || order.trackingStatus==="error");
        }
        if(tabSelected === "all"){
            return rawData.orders;
        }
    };
    
    const filterAndMapOrders = async (rawData) => {
        setisLoading(true);
        setOrdersFilteredByStatus([]);
        let filteredData = await filterByTabStatus(rawData, tab);
        if (filteredData && filteredData.length > 0) {
            const mappedData = await handleOrdersList(filteredData);
            setOrdersFilteredByStatus(mappedData);
        }
        setisLoading(false); 
    };

    return (
        <div className="grid-table">
            {isLoading ? 
                <>
                    <Skeleton count={1} height={70} style={{ marginBottom: "10px" }} />
                    <Skeleton count={1} height={30} style={{ marginBottom: "10px" }} />
                    <Skeleton count={4} height={50} style={{ marginBottom: "10px" }} />
                </>
            :
                <Table data={ordersFilteredByStatus} tabSelected={tab} updateTable={updateTable} ecommerce={ecommerce} originalLoading={isLoading} />
            }
        </div>
    )
}
