import React from 'react';
import { useAuthStore, useonboardingStore } from '../../store';
import { ButtonSmall } from '../../components/units/Button';
import { motion } from 'framer-motion';
import { customerRouteName, trackedEvents } from '../../data/InfoSource';
import { childVariants, fadeInAndRight, fadeInAndUp, growBtn } from '../../services/shared/animation.service';
import finishIllustration from '../../assets/finishIllustration.png'
import { OnboardingStepper } from '../../components/onboarding/OnboardingStepper';
import warning from '../../assets/icons/warning.png'
import { useEffect } from 'react';
import { amplitudeTrack } from '../../services/onboarding.service';

export const Finish = () => {
    const ecommerce = useAuthStore((state) => state.authData.user.ecommerce);
    const removeOnboardingData = useonboardingStore((state) => state.removeOnboardingData);
    const storeId = useAuthStore((state) => state.authData.user.storeId);

    useEffect(() => {
      amplitudeTrack("shopify-success_land", storeId, trackedEvents["shopify-success_land"]);
    }, [])

    let ecommerceUrls = {
        shopify: {
            goToPanelLink: `/${customerRouteName}/secommerce/panel/order/all`,
        },
        tiendanube: {
            goToPanelLink: `/${customerRouteName}/tiendanube/panel/order/all`,
        },
        vtex: {
            goToPanelLink: `/${customerRouteName}/vtex/sign-in`,
        },
    }

    const redirect = (url) => {
        window.location.href = `${url}`;
    }

    const btnText = {
        tiendanube: "",
        shopify: "Panel de configuraciones",
        vtex: ""
    }

    return (
        <div className="container-fluid" id="finish">
            <div className="row">
                <div className="col-3 p-0">
                    <motion.div variants={fadeInAndRight} initial="hidden" animate="visible">
                        <OnboardingStepper step={4} ecommerce={ecommerce} />
                    </motion.div>
                </div>
                <div className="col-9 content-wrapper">
                    <motion.div className="finish-wrapper" variants={fadeInAndUp} initial="hidden" animate="visible">
                        <div className='text-wrapper'>
                            <h1 className="main-title">¡Listo, guardamos tus datos!</h1>
                            <h2 className="main-subtitle">Recuerda que siempre puedes volver a editar las configuraciones que realizaste en este proceso de instalación.</h2>
                        </div>
                        <img src={finishIllustration} alt="finish" />
                        <motion.div className='notification mt-2 mt-xxl-4' variants={childVariants}>
                            <img src={warning} alt="warning" />
                            <div className="notification-text-wrapper">
                                <p className='small-subtitle'>Para terminar de configurar tu cuenta, debes ingresar obligatoriamente el teléfono desde: <span className="fw-bold">"Configuraciones" &gt; "Pantalla de pago"</span>.</p>
                            </div>
                        </motion.div>
                        <motion.div className="bottomPage" variants={growBtn} initial="hidden" animate="visible">
                            <ButtonSmall
                                ecommerce={ecommerce}
                                type="button"
                                btnTxt={btnText[ecommerce]}
                                onClickFn={() => {amplitudeTrack("shopify-success_finish_click", storeId, trackedEvents["shopify-success_finish_click"]); redirect(ecommerceUrls[ecommerce].goToPanelLink); removeOnboardingData() }}
                                showSpinner={false}
                                isDisabled={false}
                            />
                        </motion.div>
                    </motion.div>
                </div>
            </div>
        </div>
    )
}
