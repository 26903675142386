import React from 'react'
import { OnboardingNav } from './OnboardingNav';
import { LogoContainerShopify, LogoContainerTiendanube, LogoContainerVtex } from '../units/LogoContainer';

export const OnboardingStepper = ({ step, ecommerce }) => {
    return (
      <div className="stepper-container">
        {ecommerce === "shopify" && <LogoContainerShopify ecommerce={ecommerce}/>}
        {ecommerce === "tiendanube" && <LogoContainerTiendanube ecommerce={ecommerce}/>}
        {ecommerce === "vtex" && <LogoContainerVtex ecommerce={ecommerce}/>}
        <OnboardingNav step={step} ecommerce={ecommerce} />
      </div>
    );
}
