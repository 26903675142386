import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ButtonSmall } from '../../../units/Button';
import { InputsSimple, InputsTextarea } from '../../../units/Inputs';
import { showSuccess } from '../../../../services/shared/alert.service';
import { useAuthStore, useonboardingStore } from '../../../../store';
import { changeShopifyName, noSpacedNameShortener, validateFullAddress, validateInputs } from '../../../../services/shared/utils.service';
import { motion } from 'framer-motion';
import { customerRouteName, trackedEvents } from '../../../../data/InfoSource';
import { childVariants, fadeInAndUp, fadeInAndUpNoDelay, growBtn } from '../../../../services/shared/animation.service';
import { amplitudeTrack } from '../../../../services/onboarding.service';

export const LocationForm = ({ ecommerce, singleLocationIndex, allLocations, goNextStage, ...singleLocation }) => {
    const user = useAuthStore((state) => state.authData.user);
    const addData = useonboardingStore((state) => state.addData);
    const generalSwitch = useonboardingStore((state) => state.onboardingData.store?.activeAllSwitch);
    const [isLoading, setIsLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [currentLocations, setCurrentLocations] = useState(allLocations);

    const [body, setBody] = useState({
        manager: singleLocation?.manager,
        phoneNumber: singleLocation?.phoneNumber,
        notes: singleLocation?.notes,

        availableForDelivery: true,
        inCoverage: singleLocation?.inCoverage,
        locationId: singleLocation?.locationId,
        name: singleLocation?.name,
        address: singleLocation?.address,
        city: singleLocation?.city,
        province: singleLocation?.province,
        country: singleLocation?.country,
        zip: singleLocation?.zip,
        latitude: singleLocation?.latitude,
        longitude: singleLocation?.longitude,
        timeSchedule: singleLocation?.timeSchedule,
        preparationTime: singleLocation?.preparationTime,
        isAutomaticCreation: singleLocation?.isAutomaticCreation,
        ecommerceConfigured: singleLocation?.ecommerceConfigured
    })

    const handleChange = (e) => {
        setBody({ ...body, [e.target.id]: e.target.value });
    }

    const setConfiguration = async (e) => {
        e.preventDefault();
        if (validateInputs(body, setFormErrors)) {
            setIsLoading(true);
            setCurrentLocations(currentLocations[singleLocationIndex] = body);
            addData({
                store: {
                    activeAllSwitch: generalSwitch || false,
                    currentlocations: currentLocations
                },
            });
            showSuccess("¡La información de la sucursal fue completada con éxito!");
            if(!user.insidePanel){
                amplitudeTrack("shopify-locations_next_click", user.storeId, trackedEvents["shopify-locations_next_click"], {locations: [ singleLocation.address, singleLocation.latitude, singleLocation.longitude]});
            }
            goNextStage(2)
            setIsLoading(false);
        } else {
            return
        }
    }

    return (
        <motion.div id="location-form" variants={fadeInAndUp} initial="hidden" animate="visible">
            <h1 className="main-title">{singleLocation.name ? noSpacedNameShortener(singleLocation.name, 45) : `Tienda ${singleLocationIndex + 1}`}</h1>
            <h2 className="main-subtitle mb-3 mb-xl-4">{validateFullAddress([singleLocation.address, singleLocation.city, singleLocation.province])}</h2>
            <form onSubmit={setConfiguration}>
                <motion.div variants={user.insidePanel ? fadeInAndUpNoDelay : fadeInAndUp} initial="hidden" animate="visible">
                    <div className="form-grid-two-columns mb-2 mb-xl-4">
                        <motion.div variants={childVariants}>
                            <InputsSimple
                                value={body.manager}
                                id="manager"
                                type="text"
                                placeholder=""
                                labelTxt="Nombre de quien prepara los envíos*"
                                onChangeFn={handleChange}
                                resultValidation={formErrors?.manager ? "is-invalid" : ""}
                                errorTxt={false}
                            />
                        </motion.div>
                        <motion.div variants={childVariants}>
                            <InputsSimple
                                value={body.phoneNumber}
                                id="phoneNumber"
                                type="text"
                                placeholder=""
                                labelTxt="Teléfono*"
                                onChangeFn={handleChange}
                                resultValidation={formErrors?.phoneNumber ? "is-invalid" : ""}
                                errorTxt={false}
                            />
                        </motion.div>
                    </div>
                    <motion.div variants={childVariants}>
                        <InputsTextarea
                            value={body.notes}
                            id="notes"
                            placeholder="Ej. La tienda se encuentra en un segundo piso"
                            labelTxt="Observaciones"
                            onChangeFn={handleChange}
                            rows={4}
                            maxLength={900}
                            resultValidation={formErrors?.notes ? "is-invalid" : ""}
                            errorTxt={false}
                        />
                    </motion.div>
                </motion.div>
                <div className="bottomPage doubleBtn">
                    <motion.div variants={growBtn} initial="hidden" animate="visible">
                        <Link to={`/${customerRouteName}/${changeShopifyName(ecommerce)}${user.insidePanel ? '/panel' : ""}/location-list`} className="btn btn-outline-secondary">Atrás</Link>
                    </motion.div>

                    <motion.div variants={growBtn} initial="hidden" animate="visible">
                        <ButtonSmall
                            ecommerce={ecommerce}
                            type="submit"
                            btnTxt="Siguiente"
                            showSpinner={isLoading}
                            isDisabled={(body.manager && body.phoneNumber) ? false : true}
                        />
                    </motion.div>
                </div>
            </form>
        </motion.div>
    )
}
