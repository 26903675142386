import React, { useEffect, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { fadeInAndUpNoDelay } from '../../services/shared/animation.service';
import { motion } from 'framer-motion';
import { showError } from '../../services/shared/alert.service';
import { getEvidenceInfo } from '../../services/panel.service';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import mockEvidenceImg from '../../assets/mockEvidenceImg.png';

export const EvidenceModal = ({ handleClose, orderId, storeId }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [previousData, setPreviousData] = useState({});

    useEffect(() => {
        updateEvidenceInfo();
    }, []);

    const updateEvidenceInfo = async () => {
        try {
            setIsLoading(true)
            const result = await getEvidenceInfo(orderId, storeId);
            if (result && result?.success) {
                setPreviousData(result.evidence)
            }
            if (result.error) {
                showError(result.error);
            }
            setIsLoading(false)
        } catch (error) {
            showError(error)
            setIsLoading(false)
        }
    }

    const formatDate = (dateInfo) => {
        let turnToDate = new Date(dateInfo)
        let day = turnToDate.getDate() < 10 ? `0${turnToDate.getDate()}` : turnToDate.getDate();
        let month = turnToDate.getMonth() < 10 ? `0${turnToDate.getMonth()}` : turnToDate.getMonth();

        return `${day}/${month}/${turnToDate.getFullYear()}`
    }

    return (
        <Modal
            className="rounded-3"
            id="panel-evidence-modal"
            show={true}
            backdrop={true}
            onHide={handleClose}
            centered
            size="lg"
            scrollable
        >
            <Modal.Header closeButton className="modal-header border-0">
                <Modal.Title className="modal-title">Evidencia de entrega</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                {!isLoading &&
                    <motion.div variants={fadeInAndUpNoDelay} initial="hidden" animate="visible">
                        <Carousel
                            autoPlay={false}
                            infiniteLoop={false}
                            showThumbs={false}
                            showStatus={false}
                        >
                            {previousData.map((proof, index) => (
                                <div className='slider-container' key={index}>
                                    <div className="img-wrapper">
                                        <img
                                            className="d-block pt-5 pb-2"
                                            src={mockEvidenceImg} //modificar a proof.evidencePhoto
                                            alt="First slide"
                                        />
                                    </div>
                                    <ul className='legend-list'>
                                        <li className='legend-item modal-subtitle'>Entrega #{index+1}</li>
                                        <li className='legend-item'><b>Fecha:</b> {formatDate(proof.deliveredDate)}</li>
                                        <li className='legend-item'><b>Descripción:</b> {proof.description}</li>
                                    </ul>
                                </div>
                            ))}
                        </Carousel>
                    </motion.div>
                }
            </Modal.Body>
        </Modal>
    )
}