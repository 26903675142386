import React from 'react';
import { FiRotateCcw } from "react-icons/fi";

export const ButtonSmall = ({ ecommerce, type, btnTxt, showSpinner, isDisabled, onClickFn, extraClass, ...props }) => {
    return (
        <div className="d-flex justify-content-end align-items-center">
            <button type={type} className={`btn btn-primary ${ecommerce} ${extraClass}`} onClick={onClickFn} disabled={isDisabled} {...props}>
                {showSpinner ?
                    <div className="spinner-grow spinner-grow-sm" role="status">
                        <span className="visually-hidden">Cargando...</span>
                    </div>
                    :
                    btnTxt
                }
            </button>
        </div>
    )
}

export const ButtonUpdate = ({ isLoading, updateFunction }) => {
    return (
        <button className="refresh ms-4" onClick={() => updateFunction()}>
            {isLoading ?
                <div className="spinner-grow spinner-grow-sm" role="status">
                    <span className="visually-hidden">Cargando...</span>
                </div>
                :
                <FiRotateCcw />
            }
        </button>
    )
}