import React, { useState } from 'react'
import { deepCopy } from '../../../../services/shared/utils.service'
import { InputsCheckbox, InputsCheckboxAsButton } from '../../../units/Inputs'
import { HoursSchedule } from './HoursSchedule'
import { GeneralHoursSchedule } from './GeneralHoursSchedule'

export const PreparationDays = ({ weekDays, startDate, endDate, timeErrors, setWeekDays, setTimeErrors, setStartDate, setEndDate }) => {
    const [useGeneralSelection, setUseGeneralSelection] = useState(false);
    const dayLabels = ["D", "L", "M", "X", "J", "V", "S"];
    const dayNames = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];

    const handleAvailableDays = (e, selectedDay) => {
        let weekDaysCopy = deepCopy(weekDays);
        let newWeekdays = weekDaysCopy.map((day) => {
            let auxDay = day;
            if (day.day === selectedDay.day) {
                auxDay = { ...auxDay, open: !auxDay.open }
                if (auxDay.open === false) {
                    auxDay = { ...auxDay, openingHour: 0, openingMinute: 0, closingHour: 0, closingMinute: 0 }
                }
                return auxDay
            }
            return auxDay
        })
        setWeekDays(newWeekdays);
    }

    const resetClosingTime = (weekDay) => {
        return weekDays.map((day) => {
            let auxDay = day;
            if (day.day === weekDay.day) {
                return { ...auxDay, closingHour: 0, closingMinute: 0 }
            }
            setTimeErrors([...timeErrors, weekDay.day])
            return auxDay
        })
    }

    const errorHandler = ( weekDay) => {
        let errorRecordsCopy = [...timeErrors];
        let positionInErrorsRecord = errorRecordsCopy.findIndex(record => record === weekDay.day)
        if (positionInErrorsRecord > -1) {
            errorRecordsCopy.splice(positionInErrorsRecord, 1)
            setTimeErrors([...errorRecordsCopy])
        }
    }

    const handleStartDate = (date, weekDay) => {
        let singleDayStartDate = [];
        if ((weekDay?.closingHour !== 0 && weekDay?.closingMinute !== 0) && (new Date().setHours(weekDay?.closingHour, weekDay?.closingMinute, 0) < date)) {
            singleDayStartDate = resetClosingTime(weekDay);
        } else {
            singleDayStartDate = weekDays.map((day) => {
                let auxDay = day;
                if (day.day === weekDay.day) {
                    return { ...auxDay, openingHour: date.getHours(), openingMinute: date.getMinutes() }
                }
                errorHandler(weekDay);
                return auxDay
            })
        }
        setWeekDays(singleDayStartDate);
    }

    const handleEndDate = (date, weekDay) => {
        let singleDayEndDate = [];
        if (new Date().setHours(weekDay?.openingHour, weekDay?.openingMinute, 0) > date) {
            singleDayEndDate = resetClosingTime(weekDay);
        } else {
            singleDayEndDate = weekDays.map((day) => {
                let auxDay = day;
                if (day.day === weekDay.day) {
                    return { ...auxDay, closingHour: date.getHours(), closingMinute: date.getMinutes() }
                }
                errorHandler(weekDay);
                return auxDay
            })
        }
        setWeekDays(singleDayEndDate);
    }

    const handleGeneralSelection = (e) => {
        setUseGeneralSelection(e.target.checked);
        if (e.target.checked === false) {
            setStartDate(null);
            setEndDate(null);
        }
    }

    return (
        <div className="weekdays-container mb-2 mb-xl-3">
            <h2 className="main-subtitle mb-3">¿En qué días y horarios se encuentra abierta tu tienda?</h2>
            <div className='radioAsBtns-wrapper'>
                {weekDays.map((eachDay, dayIndex) => (
                    <InputsCheckboxAsButton
                        key={`day ${eachDay.day}`}
                        id={`day ${eachDay.day}`}
                        value={eachDay.day}
                        labelTxt={dayLabels[dayIndex]}
                        onChangeFn={(e) => { handleAvailableDays(e, eachDay, dayIndex) }}
                        checked={eachDay.open}
                        disabled={false}
                        extraClassName="rounded"
                    />
                ))}
            </div>
            <div style={{ margin: '2rem 0 0' }}>
                <InputsCheckbox
                    id="generalSelection"
                    value={useGeneralSelection}
                    labelTxt="Configuración múltiple"
                    onChangeFn={handleGeneralSelection}
                    checked={useGeneralSelection}
                />
                {useGeneralSelection ?
                    <div style={{ marginTop: '1.5rem' }}>
                        <GeneralHoursSchedule
                            weeks={weekDays}
                            setWeeks={setWeekDays}
                            dayNames={dayNames}
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                        />
                    </div>
                    :
                    <div style={{ marginTop: '1.5rem' }}>
                        {weekDays.filter(days => days.open)
                            .map((eachDay, index) => (
                                <HoursSchedule
                                    key={index}
                                    day={eachDay}
                                    dayNames={dayNames[eachDay.day]}
                                    handleStartDate={handleStartDate}
                                    startDate={startDate}
                                    handleEndDate={handleEndDate}
                                    endDate={endDate}
                                    timeErrors={timeErrors}
                                />
                            ))
                        }
                    </div>
                }
            </div>
        </div>
    )
}
