import React, { useEffect, useState } from 'react'
import { ButtonSmall } from '../../../units/Button';
import { motion } from 'framer-motion';
import { childVariants, fadeInAndUp, growBtn } from '../../../../services/shared/animation.service';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { changeShopifyName, deepCopy, getCountryCoords } from '../../../../services/shared/utils.service';
import { customerRouteName } from '../../../../data/InfoSource';
import { useAuthStore, useonboardingStore } from '../../../../store';
import { configSingleLocation, verifyNewLocation } from '../../../../services/onboarding.service';
import { showError, showSuccess } from '../../../../services/shared/alert.service';
import { AddressSearchAndMap } from './AddressSearchAndMap';
import { NewLocInfoForm } from './NewLocInfoForm';

export const OutOfCoverageContainer = ({ ecommerce }) => {
    const location = useLocation();
    let history = useHistory();
    const store = useonboardingStore((state) => state.onboardingData.store);
    const user = useAuthStore((state) => state.authData.user);
    const [isLoading, setIsLoading] = useState(true);
    const [address, setAddress] = useState("")
    const [isAddressAvailable, setIsAddressAvailable] = useState(false);
    const [newAddressInfo, setNewAddressInfo] = useState({});
    const [formErrors, setformErrors] = useState({});
    const [locationInitialCoordinates, setLocationInitialCoordinates] = useState([0, 0]);
    const [singleLocation, setSingleLocation] = useState({});
    const [isFormComplete, setIsFormComplete] = useState(false);
    const nextShopifyRouteParam = user?.insidePanel ? "panel/location-list" : "location-list";

    useEffect(() => {
        setIsLoading(true)
        let pathArray = location.pathname.split("/");
        let selectedId = pathArray[pathArray.length - 1];
        let list = store.currentlocations;
        let singleLocSelected = list.find(loc => loc.locationId === selectedId);
        setSingleLocation(singleLocSelected);
        if (singleLocSelected?.latitude && singleLocSelected?.longitude) {
            setLocationInitialCoordinates([parseFloat(singleLocSelected.latitude), parseFloat(singleLocSelected.longitude)])
            setIsLoading(false)
        } else {
            let defaultCoordByCountry = getCountryCoords(singleLocSelected.country);
            setLocationInitialCoordinates([parseFloat(defaultCoordByCountry.lat), parseFloat(defaultCoordByCountry.lng)])
            setIsLoading(false)
        }
        setIsLoading(false)
    }, [])

    const handleVerification = async (e) => {
        e.preventDefault();
        if (address) {
            setIsLoading(true);
            try {
                const result = await verifyNewLocation({ "address": address }, user.storeId);
                if (result && result.success) {
                    showSuccess(result.message);
                    setIsAddressAvailable(true)
                }
                if (!result.success) {
                    showError(result.message);
                    setIsAddressAvailable(false)
                }
                setIsLoading(false);
            } catch (error) {
                showError(error);
                setIsAddressAvailable(false)
                setIsLoading(false);
            }
        } else {
            return
        }
    }

    const handleReset = () => {
        setIsLoading(true);
        setIsAddressAvailable(false);
        setNewAddressInfo({});
        setformErrors({});
        setIsLoading(false);
    }

    const setNewAddress = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        let locationCopy = deepCopy(singleLocation);
        let finalLocationInfo = { ...locationCopy, ...newAddressInfo, inCoverage: true, ecommerceConfigured: true}
        try {
            const result = await configSingleLocation({ location: finalLocationInfo }, user.storeId);
            if (result && result.success) {
                showSuccess(result.message);
                history.push(`/${customerRouteName}/${changeShopifyName(ecommerce)}/${nextShopifyRouteParam}`);
            }
            if (result.error) {
                showError(result.error);
            }
            setIsLoading(false);
        } catch (error) {
            showError(error);
            setIsLoading(false);
        }
    }

    return (
        <div id="outOfCoverage">
            <motion.div className="outOfCoverage-wrapper" variants={fadeInAndUp} initial="hidden" animate="visible">
                <h2 className="intro-subtitle">{!isAddressAvailable ? "Introduce una dirección y comprueba que esté operativa." : "Completa los datos faltantes, en caso de ser necesario."}</h2>
                {(!isLoading && !isAddressAvailable) &&
                    <div className="formAndMap-wrapper">
                        <form onSubmit={handleVerification}>
                            <motion.div className='address-verfiy-form' variants={childVariants}>
                                <AddressSearchAndMap setAddressToVerify={setAddress} locationInitialCoordinates={locationInitialCoordinates} setNewAddressDetails={setNewAddressInfo} originalInfo={singleLocation} />
                                <ButtonSmall
                                    type="submit"
                                    btnTxt="Comprobar dirección"
                                    showSpinner={isLoading}
                                    isDisabled={address ? false : true}
                                    extraClass="checkAddress-btn"
                                />
                            </motion.div>
                        </form>
                    </div>
                }
                {(!isLoading && isAddressAvailable) &&
                    <NewLocInfoForm newAddressInfo={newAddressInfo} setNewAddressInfo={setNewAddressInfo} formErrors={formErrors} resetNewAddress={handleReset} setIsFormComplete={setIsFormComplete} />
                }
            </motion.div>
            <div className="bottomPage doubleBtn">
                <motion.div variants={growBtn} initial="hidden" animate="visible">
                    <Link to={`/${customerRouteName}/${changeShopifyName(ecommerce)}${user?.insidePanel ? '/panel' : ""}/location-list`} className="btn btn-outline-secondary">Atrás</Link>
                </motion.div>

                <motion.div variants={growBtn} initial="hidden" animate="visible">
                    <ButtonSmall
                        type="button"
                        btnTxt="Guardar"
                        onClickFn={setNewAddress}
                        showSpinner={isLoading}
                        isDisabled={(isAddressAvailable && isFormComplete) ? false : true}
                    />
                </motion.div>
            </div>
        </div>
    )
}