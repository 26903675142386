import React from 'react'
import DatePicker from 'react-datepicker'

export const HoursSchedule = ({ day, dayNames, handleStartDate, handleEndDate, startDate, endDate, timeErrors }) => {
    return (
        <div className='week-hours-container'>
            <p>{dayNames}</p>
            <div className='personalized-week-hours-wrapper'>
                <p>de</p>
                <DatePicker
                    selected={day.openingHour !== 0 ? new Date().setHours(day?.openingHour, day?.openingMinute, 0) : startDate}
                    onChange={(date) => handleStartDate(date, day)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    excludeTimes={[
                        new Date().setHours(0, 0, 0),
                        new Date().setHours(0, 30, 0),
                    ]}
                    timeCaption="Time"
                    dateFormat="hh:mm aa"
                    className="custom-picker-input"
                    wrapperClassName="custom-picker-wrapper"
                    placeholderText="Ej. 13:00"
                />
                <p>a</p>
                <DatePicker
                    selected={day.closingHour !== 0 ? new Date().setHours(day?.closingHour, day?.closingMinute, 0) : endDate}
                    onChange={(date) => handleEndDate(date, day)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    excludeTimes={[
                        new Date().setHours(0, 0, 0),
                        new Date().setHours(0, 30, 0),
                    ]}
                    timeCaption="Time"
                    dateFormat="hh:mm aa"
                    className="custom-picker-input"
                    placeholderText="Ej. 18:00"
                />
                {(timeErrors.length > 0 && timeErrors.some(record => record === day.day)) &&
                    <p className='invalid-msg'>El horario de finalización debe ser posterior al de inicio</p>
                }
            </div>
        </div>
    )
}
