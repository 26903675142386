import React, { useState, useEffect } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { GridSetter } from '../../components/panel/GridSetter';
import { useAuthStore } from '../../store';
import { showError } from '../../services/shared/alert.service';
import { getOrders } from '../../services/panel.service';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const GridContainer = ({ ecommerce, shop, store }) => {
  const user = useAuthStore((state) => state.authData.user);
  const addAuth = useAuthStore((state) => state.addAuthData);
  const [tabSelected, setTabSelected] = useState("all");
  const [rawData, setRawData] = useState({});
  const [isLoading, setisLoading] = useState(true);

  const getOrdersList = async(storeId, tabToUpdate) => {
    setisLoading(true);
    if(tabToUpdate !== tabSelected){
      setTabSelected(tabToUpdate)
    }
    try {
      const result = await getOrders(storeId);
      if(result && result.success){
        setRawData(result);
        addAuth({
          user: {
            ...user,
            storeId: storeId || "",
            ecommerce: ecommerce,
            insidePanel: true,
          },
        })
      }
      setisLoading(false);
    } catch (error) {
      showError(error);
    }
  }

  useEffect(() => {
    if(shop || store){
      if(ecommerce==="tiendanube"){
        getOrdersList(store, "all")
      } else {
        getOrdersList(shop, "all")
      }
    } else {
      if(user?.storeId){
        getOrdersList(user?.storeId, "all")
      }
      if(ecommerce==="vtex"){
        getOrdersList("", "all")
      }
    }
  }, [])

  return (
    <div className="grid-container">
      {isLoading ? 
          <>
              <Skeleton count={1} height={70} />
          </>
      :
      <Tabs defaultActiveKey="all" id="orders-tab" onSelect={(e) => setTabSelected(e)}>
        <Tab
          eventKey="all"
          title="Todas"
        >
          {tabSelected === "all" && (
            <GridSetter tab="all" ecommerce={ecommerce} rawData={rawData} updateTable={getOrdersList} />
          )}
        </Tab>

        <Tab
          eventKey="pending"
          title="Por enviar"
        >
          {tabSelected === "pending" && (
            <GridSetter tab="pending" ecommerce={ecommerce} rawData={rawData} updateTable={getOrdersList} />
          )}
        </Tab>

        <Tab
          eventKey="created"
          title="Confirmadas"
        >
          {tabSelected === "created" && (
            <GridSetter tab="created" ecommerce={ecommerce} rawData={rawData} updateTable={getOrdersList} />
          )}
        </Tab>

        <Tab
          eventKey="delivered"
          title="Entregadas"
        >
          {tabSelected === "delivered" && (
            <GridSetter tab="delivered" ecommerce={ecommerce} rawData={rawData} updateTable={getOrdersList}/>
          )}
        </Tab>

        <Tab
          eventKey="issue"
          title="Con problemas"
        >
          {tabSelected === "issue" && (
            <GridSetter tab="issue" ecommerce={ecommerce} rawData={rawData} updateTable={getOrdersList} />
          )}
        </Tab>
      </Tabs>
      }
    </div>
  );
}
