import { prepareFixedInfo } from "../services/shared/utils.service";

export const customerName = "Cabify"

export const customerRouteName = "cabify"

export const urlInfo = {
    shopify: {
        contactUs: "https://help.cabify.com/hc/es/requests/new",
        createAccount: "https://app.cabify.com/signup"
    },
    tiendanube: {
        contactUs: "https://help.cabify.com/hc/es/requests/new",
        createAccount: "https://app.cabify.com/signup"
    },
    vtex: {
        contactUs: "https://help.cabify.com/hc/es/requests/new",
        createAccount: "https://app.cabify.com/signup"
    },
}

export const maxInputLength = 900;


//Amplitude analytics

export const trackedEvents = {
    "shopify-welcome_create_cabify_account_click": prepareFixedInfo("welcome page", "shopify-welcome_create_cabify_account_click", "user clicks 'crear aquí' to create a cabify account if they didn't have one", "optional"),
    "shopify-credentials_land": prepareFixedInfo("token auth", "shopify-credentials_land", "user lands on the credentials page (token form)"),
    "shopify-credentials_next_click": prepareFixedInfo("token auth", "shopify-credentials_next_click", "user clicks on 'Siguiente' on credentials page"),
    "shopify-locations_land": prepareFixedInfo("location configuration", "shopify-locations_land", "user lands on the location configuration page"),
    "shopify-locations_next_click": prepareFixedInfo("location configuration", "shopify-locations_next_click", "user clicks on 'Siguiente' on the location configuration page"),
    "shopify-delivery_land": prepareFixedInfo("delivery configuration", "shopify-delivery_land", "user lands on the delivery configuration page"),
    "shopify-delivery_next_click": prepareFixedInfo("delivery configuration", "shopify-delivery_next_click", "user clicks on 'Siguiente' on the delivery configuration page"),
    "shopify-cost_land": prepareFixedInfo("cost configuration", "shopify-cost_land", "user lands on the cost configuration page"),
    "shopify-cost_next_click": prepareFixedInfo("cost configuration", "shopify-cost_next_click", "user clicks on 'Siguiente' on the cost configuration page"),
    "shopify-package_land": prepareFixedInfo("cost configuration", "shopify-package_land", "user lands on the package configuration page"),
    "shopify-package_next_click": prepareFixedInfo("cost configuration", "shopify-package_next_click", "user clicks on 'Siguiente' on the package configuration page"),
    "shopify-success_land": prepareFixedInfo("success", "shopify-success_land", "user lands on the success page"),
    "shopify-success_finish_click": prepareFixedInfo("success", "shopify-success_finish_click", "user clicks on 'Panel de configuraciones' on the success page", "optional"),
};