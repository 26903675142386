import React, { useState } from 'react'
import { changeShopifyName, validateInputs } from '../../../services/shared/utils.service';
import { useHistory } from 'react-router';
import { amplitudeTrack, loginService } from '../../../services/onboarding.service';
import { showError, showSuccess } from '../../../services/shared/alert.service';
import { useAuthStore } from '../../../store';
import { ButtonSmall } from '../../units/Button';
import { InputsSimple } from '../../units/Inputs';
import { motion } from 'framer-motion';
import { customerRouteName, trackedEvents } from '../../../data/InfoSource';
import { childVariants, growBigBtn } from '../../../services/shared/animation.service';

export const LoginForm = ({ ecommerce }) => {
    const addAuth = useAuthStore((state) => state.addAuthData);
    const user = useAuthStore((state) => state.authData.user);
    let history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({})
    const [body, setBody] = useState({
        customerApiKey: "",
        customerApiToken: "",
    });

    const handleChange = (e) => {
        setBody({ ...body, [e.target.id]: (e.target.value).trim() })
    }

    const signIn = async (e) => {
        e.preventDefault();
        if (validateInputs(body, setFormErrors)) {
            setIsLoading(true);
            try {
                const result = await loginService(body, user.storeId);
                if (result && result.success) {
                    addAuth({
                        user: {
                            ...user,
                            customerApiKey: body.customerApiKey,
                            customerApiToken: body.customerApiToken,
                        }
                    });
                    showSuccess(result.message);
                    amplitudeTrack("shopify-credentials_next_click", user.storeId, trackedEvents["shopify-credentials_next_click"], {"auth-success": true});
                    history.push(`/${customerRouteName}/${changeShopifyName(ecommerce)}/location-list`)
                }
                if (result.error) {
                    showError(result.error);
                }
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                amplitudeTrack("shopify-credentials_next_click", user.storeId, trackedEvents["shopify-credentials_next_click"], {"auth-success": false});
                showError(error);
            }
        } else {
            return
        }
    }

    return (
        <form onSubmit={signIn}>
            <motion.div variants={childVariants}>
                <InputsSimple 
                value={body.customerApiKey}
                id="customerApiKey"
                type="text"
                placeholder=""
                labelTxt="API Key*"
                onChangeFn={handleChange}
                resultValidation = {formErrors?.customerApiKey ? "is-invalid" : ""}
                errorTxt={false}
                />
            </motion.div>

            <motion.div variants={childVariants}>
                <InputsSimple 
                value={body.customerApiToken}
                id="customerApiToken"
                type="text"
                placeholder=""
                labelTxt="API Secret*"
                onChangeFn={handleChange}
                resultValidation={formErrors?.customerApiToken ? "is-invalid" : ""}
                errorTxt={false}
                />
            </motion.div>

            <motion.div variants={growBigBtn} className="login-btn">
                <ButtonSmall 
                ecommerce={ecommerce}
                type="submit"
                btnTxt="Ingresar"
                showSpinner={isLoading}
                isDisabled={(body.customerApiKey && body.customerApiToken) ? false : true}
                />
            </motion.div>
        </form>
    )
}
