import { motion } from "framer-motion";

const ActionLoader = () => {
  return (
    <motion.div
      id="action-loader"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <div className="spinner-grow spinner-grow-sm" role="status">
        <span className="visually-hidden">Cargando...</span>
      </div>
    </motion.div>
  );
};

export default ActionLoader;
