import React from 'react';
import Modal from "react-bootstrap/Modal";
import { ButtonSmall } from '../units/Button';

export const ConfirmationModal = ({ handleClose, bodyText,  mainTask }) => {
  return (
    <Modal
      className="rounded-3"
      id="panel-confirm-modal"
      show={true}
      backdrop={true}
      onHide={handleClose}
      centered
      size="lg"
      scrollable
    >
      <Modal.Header closeButton className="modal-header border-0">
      </Modal.Header>
      <Modal.Body className="modal-body intro-title">
        {bodyText}
      </Modal.Body>
      <Modal.Footer className="modal-footer d-flex justify-content-between border-0">
        <ButtonSmall
          type="button"
          btnTxt="No"
          showSpinner={false}
          isDisabled={false}
          onClickFn={() => handleClose()}
          extraClass="btn btn-outline-primary"
        />
        <ButtonSmall
          type="button"
          btnTxt="Sí"
          showSpinner={false}
          isDisabled={false}
          onClickFn={() => {mainTask(); handleClose()}}
          extraClass="btn btn-primary"
        />
      </Modal.Footer>
    </Modal>
  )
}
