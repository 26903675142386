import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router";
import { useAuthStore, useonboardingStore } from '../../store';
import { InCoverageFlowContainer } from '../../components/onboarding/locationConfig/inCoverage/InCoverageFlowContainer';
import { OutOfCoverageContainer } from '../../components/onboarding/locationConfig/outOfCoverage/OutOfCoverageContainer';

export const LocationSinglePanel = () => {
    const location = useLocation();
    const ecommerce = useAuthStore((state) => state.authData.user.ecommerce);
    const store = useonboardingStore((state) => state.onboardingData.store);
    const [singleLocation, setSingleLocation] = useState({});
    const [singleLocationIndex, setSingleLocationIndex] = useState(null);
    const [allLocations, setAllLocations] = useState(store?.currentlocations);

    useEffect(() => {
        let pathArray = location.pathname.split("/");
        let selectedId = pathArray[pathArray.length - 1];
        let list = store.currentlocations;
        let singleLocSelected = list.find(loc => loc.locationId === selectedId);
        let singleLocIndex = list.findIndex(loc => loc.locationId === selectedId);
        setSingleLocation(singleLocSelected);
        setSingleLocationIndex(singleLocIndex);
    }, [])

    return (
        <div id="locationSinglePanel">
            {singleLocation?.inCoverage ?
                <InCoverageFlowContainer ecommerce={ecommerce} singleLocationIndex={singleLocationIndex} allLocations={allLocations} singleLocation={singleLocation} />
                :
                <OutOfCoverageContainer ecommerce={ecommerce} />
            }
        </div>
    )
}
