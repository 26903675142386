import React, { useEffect } from 'react'
import { OnboardingStepper } from '../../components/onboarding/OnboardingStepper';
import { useAuthStore } from '../../store';
import { motion } from 'framer-motion';
import { fadeInAndRight } from '../../services/shared/animation.service';
import { LocationsConfig } from '../../components/onboarding/locationConfig/LocationsConfig';
import { amplitudeTrack } from '../../services/onboarding.service';
import { trackedEvents } from '../../data/InfoSource';

export const LocationsList = () => {
  const user = useAuthStore((state) => state.authData.user);

  useEffect(() => {
    amplitudeTrack("shopify-locations_land", user.storeId, trackedEvents["shopify-locations_land"]);
  }, [])

  return (
    <div className="container-fluid" id="locationList">
      <div className="row">
        <div className="col-3 p-0">
          <motion.div variants={fadeInAndRight} initial="hidden" animate="visible">
            <OnboardingStepper step={1} ecommerce={user.ecommerce} />
          </motion.div>
        </div>
        <div className="col-9">
          <LocationsConfig />
        </div>
      </div>
    </div>
  )
}
