import React from 'react';
import { motion } from 'framer-motion';
import { childVariants } from '../../services/shared/animation.service';

export const OnboardingNav = ({ step, ecommerce }) => {

  const pages = ["Tiendas y tipos de envío", "Costos de envío", "Tamaños de Producto", "Guardado"];
  const pagesTiendanube = ["Tu tienda", "Tarifa y creación", "Guardado"];
  const pagesVtex = ["Tus tiendas", "Guardado"];

  return (
    <div className="col-6 onboarding-nav">
      {ecommerce === "vtex" && (
        <ul className="pages-titles-list">
          {pagesVtex.map((page, index) => (
            <NavListItem key={index} step={step} page={page} index={index} />
          ))}
        </ul>
      )}
      {ecommerce === "shopify" && (
        <ul className="pages-titles-list">
          {pages.map((page, index) => (
            <NavListItem key={index} step={step} page={page} index={index} />
          ))}
        </ul>
      )}
      {ecommerce === "tiendanube" && (
        <ul className="pages-titles-list">
          {pagesTiendanube.map((page, index) => (
            <NavListItem key={index} step={step} page={page} index={index} />
          ))}
        </ul>
      )}
    </div>
  );
}

export const NavListItem = ({ step, page, index }) => {
  return (
    <motion.li variants={childVariants} key={index} className={index + 1 === step ? "selected" : ""}>
      <div className="circle"></div>
      <span>{page}</span>
    </motion.li>
  )
}