import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { changeShopifyName, noSpacedNameShortener, validateFullAddress } from "../../../services/shared/utils.service";
import { InputsSwitch } from "../../units/Inputs";
import { FiChevronRight, FiMinus, FiAlertTriangle } from "react-icons/fi";
import { customerRouteName } from '../../../data/InfoSource';

export const LocationItem = ({ index, value, ecommerce, id, checked, handleChange, insidePanel, ...location }) => {
  const [isConfigurated, setIsConfigurated] = useState(false);

  useEffect(() => {
    if (location.manager && location.phoneNumber && location.isEveryOpenDayConfigurated) {
      setIsConfigurated(true)
    }
  }, [])

  return (
    <>
      {location.ecommerceConfigured || ecommerce!=="shopify" ?
        <div className="locationItem-grid mb-2">
          <InputsSwitch
            id={id}
            value={value}
            checked={checked}
            onChangeFn={handleChange}
            labelTxt={location.name ? noSpacedNameShortener(location.name, 31) : `Tienda ${index}`}
          />
          <div className="address-wrapper">
            <h3>{validateFullAddress([location.address, location.city])}</h3>
            {location.availableForDelivery &&
              <small className={isConfigurated ? "success" : ""}>{isConfigurated ? `Tienda configurada` : `Debes configurar tu tienda`}</small>
            }
          </div>
          {location.availableForDelivery &&
            <Link to={`/${customerRouteName}/${changeShopifyName(ecommerce)}${insidePanel ? '/panel' : ""}/location-list/${id}`} className="step-subtitle">Editar <FiChevronRight /></Link>
          }
        </div>
        :
        <div className="locationItem-grid warning mb-2">
          <div className="noSwitch">
            <FiAlertTriangle size={20} />
            <h4 className="form-check-label label-bold">{location.name ? noSpacedNameShortener(location.name, 31) : `Tienda ${index}`}</h4>
          </div>
          <div className="address-wrapper">
            <h3 className="step-subtitle">{validateFullAddress([location.address, location.city])}</h3>
            <small>Configuración incompleta para Shopify</small>
          </div>
          <Link to={`/${customerRouteName}/${changeShopifyName(ecommerce)}${insidePanel ? '/panel' : ""}/location-list/${id}`} className="step-subtitle">Configurar <FiChevronRight /></Link>
        </div>
      }
    </>
  );
};

export const LocationItemError = ({ index, ecommerce, id, insidePanel, ...location }) => {
  return (
    <>
      {location.ecommerceConfigured || ecommerce!=="shopify" ?
        <div className="locationItem-grid danger mb-2">
          <div className="noSwitch">
            <FiMinus />
            <h4 className="form-check-label label-bold">{location.name ? noSpacedNameShortener(location.name, 31) : `Tienda ${index}`}</h4>
          </div>
          <div className="address-wrapper">
            <h3>{validateFullAddress([location.address, location.city])}</h3>
            <small>Tienda fuera de zona operativa</small>
          </div>
          <Link to={`/${customerRouteName}/${changeShopifyName(ecommerce)}${insidePanel ? '/panel' : ""}/location-list/${id}`} className="step-subtitle">Configurar <FiChevronRight /></Link>
        </div>
        :
        <div className="locationItem-grid warning mb-2">
          <div className="noSwitch">
            <FiAlertTriangle size={20} />
            <h4 className="form-check-label label-bold">{location.name ? noSpacedNameShortener(location.name, 31) : `Tienda ${index}`}</h4>
          </div>
          <div className="address-wrapper">
            <h3 className="step-subtitle">{validateFullAddress([location.address, location.city])}</h3>
            <small>Configuración incompleta para Shopify</small>
          </div>
          <Link to={`/${customerRouteName}/${changeShopifyName(ecommerce)}${insidePanel ? '/panel' : ""}/location-list/${id}`} className="step-subtitle">Configurar <FiChevronRight /></Link>
        </div>
      }
    </>
  );
};
