import React, { useState } from 'react';
import { LocationForm } from './LocationForm';
import { LocationTimesAndMethod } from './LocationTimesAndMethod';

export const InCoverageFlowContainer = ({ ecommerce, singleLocationIndex, allLocations, singleLocation }) => {
    const [configStage, setconfigStage] = useState(1);
    
    return (
        <div id='InCoverageFlowContainer'>
            {configStage === 1 ?
                <LocationForm ecommerce={ecommerce} singleLocationIndex={singleLocationIndex} allLocations={allLocations} goNextStage={setconfigStage} {...singleLocation} />
                :
                <LocationTimesAndMethod ecommerce={ecommerce} goNextStage={setconfigStage} />
            }
        </div>
    )
}
