import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { Route, Switch } from 'react-router-dom';
import {
  capitalizeFirstLetter,
  changeShopifyName,
  setEcommerceURL,
} from '../../../services/shared/utils.service';
import { useAuthStore } from '../../../store';
import { customerName } from '../../../data/InfoSource';
import { FiChevronsRight, FiChevronsLeft } from 'react-icons/fi';
import { PanelMainMenu } from '../../../components/panel/PanelMainMenu';
import { LogoContainerShopify } from '../../../components/units/LogoContainer';
import { GridContainer } from '../../../pages/panel/GridContainer';
import { LocationsConfig } from '../../../components/onboarding/locationConfig/LocationsConfig';
import { RatesForm } from '../../../components/onboarding/rates/RatesForm';
import { ConfigurationsForm } from '../../../components/onboarding/settings/ConfigurationsForm';
import { LocationSinglePanel } from '../../../pages/panel/LocationSinglePanel';

export default function PanelRoute({ rootCustomerPath }) {
  const location = useLocation();
  const [ecommerce, setEcommerce] = useState(setEcommerceURL(location));
  const user = useAuthStore((state) => state.authData.user);
  const removeAuth = useAuthStore((state) => state.removeAuthData);
  const [isCondensed, setIsCondensed] = useState(false);

  const { shop = '' } = queryString.parse(location.search);
  const { store = '' } = queryString.parse(location.search);

  useEffect(() => {
    if (window.innerWidth < 1280) {
      setIsCondensed(true);
    } else {
      setIsCondensed(false);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    if (user && user?.storeId && (shop || store)) {
      if (shop && user?.storeId !== shop) {
        removeAuth();
      }
      if (store && user?.storeId !== store) {
        removeAuth();
      }
    }
    document.title = `${customerName} - ${capitalizeFirstLetter(ecommerce)}`;
  }, []);

  return (
    <div className="container-fluid" id="panel-m">
      <div className="row row-grid">
        <div className="col-12">
          <div className={`main-nav ${isCondensed ? 'condensed' : ''}`}>
            <div className="action-condensedBtn">
              <button
                type="button"
                onClick={() => setIsCondensed(!isCondensed)}
              >
                {isCondensed ? <FiChevronsRight /> : <FiChevronsLeft />}
              </button>
            </div>
            {ecommerce === 'shopify' && (
              <LogoContainerShopify
                ecommerce={ecommerce}
                isCondensed={isCondensed}
              />
            )}
            <PanelMainMenu ecommerce={ecommerce} isCondensed={isCondensed} />
          </div>
          <div className="content-wrapper">
            <Switch>
              <Route
                path={`/${rootCustomerPath}/${changeShopifyName(
                  ecommerce
                )}/panel/order`}
                render={() => (
                  <GridContainer
                    ecommerce={ecommerce}
                    shop={shop}
                    store={store}
                  />
                )}
              />
              <Route
                exact
                path={`/${rootCustomerPath}/secommerce/panel/location-list`}
              >
                {' '}
                <LocationsConfig />
              </Route>
              <Route
                exact
                path={`/${rootCustomerPath}/secommerce/panel/location-list/:id`}
              >
                {' '}
                <LocationSinglePanel />
              </Route>
              <Route
                path={`/${rootCustomerPath}/secommerce/panel/rates`}
                render={() => <RatesForm ecommerce={ecommerce} />}
              />
              <Route
                exact
                path={`/${rootCustomerPath}/secommerce/panel/settings`}
              >
                {' '}
                <ConfigurationsForm />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}
