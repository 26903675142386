import React from 'react'
import { childVariants } from '../../../services/shared/animation.service';
import { motion } from 'framer-motion';
import { InputsRadio } from '../../units/Inputs';

export const RatesFormTypeInputs = ({ ...serviceProps }) => {

    const handleServiceChange = (e) => {
        serviceProps.setServiceType(e.target.value);
    }

    return (
        <div>
            <div>
                <h2 className="main-title mt-4 mt-xxl-5">Tarifa de servicio</h2>
                <h3 className="main-subtitle">Selecciona el tipo de servicio que deseas ofrecerle a tus clientes.</h3>
            </div>
            <motion.div className='mt-2 mt-xxl-3' variants={childVariants}>
            <InputsRadio
                id="express"
                name="serviceType"
                value="express"
                labelTxt="Express"
                onChangeFn={handleServiceChange}
                checked={serviceProps.serviceType === "express"}
            />
            <InputsRadio
                id="same_next"
                name="serviceType"
                value="same_next"
                labelTxt="Same/Next day"
                onChangeFn={handleServiceChange}
                checked={serviceProps.serviceType === "same_next"}
            />
            </motion.div>
        </div>
    )
}
