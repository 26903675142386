import { Route, Switch } from "react-router-dom";
import CustomerShopifyRoute from './shopify/customerShopifyRoute';

export default function CustomerRoute({ rootCustomerPath }) {
  return (
    <>
        <Switch>
            <Route path={`/${rootCustomerPath}/secommerce`} render={() => <CustomerShopifyRoute rootCustomerPath={rootCustomerPath} />} />
        </Switch>
    </>
  );
}
