import React, { useEffect, useState } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { useAuthStore } from '../../store';
import { motion } from 'framer-motion';
import { getDeliveryCost, setOrdersAction } from '../../services/grid.service';
import { childVariants, fadeInAndUpNoDelay } from '../../services/shared/animation.service';
import { columns, filterData, handleDateChange, noSelection, onRowSelect, onSelectAll, options, printTickets } from '../../services/grid.service';
import Skeleton from 'react-loading-skeleton';
import { FiRotateCcw, FiSearch, FiDownload } from "react-icons/fi";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import { ConfirmationModal } from './ConfirmationModal';
import { MoreInfoModal } from './moreInfoModal/MoreInfoModal';
import { EvidenceModal } from './EvidenceModal';
import { TableMassiveBtns } from './TableMassiveBtns';
import ActionLoader from './ActionLoader';
registerLocale("es", es);

export const Table = ({ data, tabSelected, updateTable, originalLoading }) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const auth = useAuthStore((state) => state.authData);
  const [filteredByDateList, setFilteredByDateList] = useState(data);
  const [isLoading, setIsLoading] = useState(originalLoading);
  const [ordersSelected, setOrdersSelected] = useState([]);
  const [ordersForCancel, setOrdersForCancel] = useState([]);
  const [showEvidenceModal, setShowEvidenceModal] = useState(false);
  const [evidenceModalProps, setEvidenceModalProps] = useState({});
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationModalProps, setConfirmationModalProps] = useState({});
  const [showMoreInfoModal, setShowMoreInfoModal] = useState(false);
  const [moreInfoModalProps, setMoreInfoModalProps] = useState({});
  const [showEmptyMsg, setshowEmptyMsg] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loadingBtnAction, setLoadingBtnAction] = useState(false)

  useEffect(() => {
    if (data.length === 0) {
      setshowEmptyMsg(true)
    } else {
      setshowEmptyMsg(false)
    }
  }, [data, tabSelected]);

  // --- row selection ---
  const selectRowModes = {
    mode: "checkbox",
    clickToSelect: false,
    onSelect: (row, isSelected) => onRowSelect(row, isSelected, ordersSelected, setOrdersSelected, ordersForCancel, setOrdersForCancel),
    onSelectAll: (isSelected, rows) => onSelectAll(isSelected, rows, setOrdersSelected, setOrdersForCancel),
    bgColor: () => {
      return `#f6f6f6`;
    }
  };

  const resetOrdersSelections = () => {
    setOrdersSelected([])
    setOrdersForCancel([])
  }

  // -- formatters extra props --
  let infoExtraData = { setMoreInfoModalProps, setShowMoreInfoModal, auth };
  let followUpProps = { setEvidenceModalProps, setShowEvidenceModal, auth, setConfirmationModalProps, setShowConfirmationModal, setOrdersAction, tabSelected, setIsLoading, updateTable, resetOrdersSelections, setLoadingBtnAction }
  let actionButtonsProps = { setOrdersAction, auth, tabSelected, setIsLoading, updateTable, resetOrdersSelections, setConfirmationModalProps, setShowConfirmationModal };
  let deliveryCostProps = { getDeliveryCost, auth, tabSelected, setIsLoading, updateTable };

  //massive btns props
  let massiveBtnProps = { ordersSelected, setConfirmationModalProps, setShowConfirmationModal, setOrdersAction, auth, setIsLoading, updateTable, resetOrdersSelections, ordersForCancel, printTickets, setOrdersSelected }

  // --- needed for enabeling search bar ---
  const { SearchBar } = Search;

  // --- table ---
  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <div>
      <ToolkitProvider
        keyField="orderId"
        data={filteredByDateList}
        columns={columns(infoExtraData, followUpProps, actionButtonsProps, deliveryCostProps)}
        search
      >
        {(props) => (
          <motion.div id="fulltable" className="container-fluid" variants={fadeInAndUpNoDelay} initial="hidden" animate="visible">
            {showEmptyMsg && <div className="empty-msg"><p className="finish-small-title">Aún no hay órdenes para mostrar</p></div>}
            <div className="d-flex flex-column justify-content-start h-100">
              <div className="topBar-container">
                <div className="search-container">
                  <SearchBar
                    {...props.searchProps}
                    className="custom-search-field"
                    placeholder="Buscar órdenes..."
                    srText="Buscar"
                  />
                  <FiSearch />
                </div>
                <DatePicker
                  locale="es"
                  selected={startDate}
                  onChange={(dates) => handleDateChange(dates, setStartDate, setEndDate)}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  onCalendarClose={() => filterData(data, startDate, endDate, setFilteredByDateList)}
                  dateFormat="dd/MM/yy"
                  placeholderText="Filtrar por fecha"
                  className="custom-range-input"
                />
                {startDate !== null &&
                  <button className="close-btn" onClick={() => { setEndDate(null); setStartDate(null); setFilteredByDateList(data) }}>X</button>
                }
                {ordersSelected.length > 1 &&
                  (tabSelected !== "all" || tabSelected !== "issue") && (
                    <TableMassiveBtns tabSelected={tabSelected} {...massiveBtnProps} />
                  )
                }
                <div className="update-btn-container">
                  <a href={`${baseUrl}/embedded/panel/create-excel?shop=${auth?.user?.storeId}`} rel="noreferrer" className='btn-link me-2'>
                    Descargar excel <FiDownload className='ms-1' />
                  </a>
                  <button
                    onClick={() => { updateTable(auth?.user?.storeId, tabSelected) }}
                  >
                    <FiRotateCcw />
                  </button>
                </div>
              </div>
              {showEvidenceModal && <EvidenceModal {...evidenceModalProps} />}
              {showMoreInfoModal && <MoreInfoModal {...moreInfoModalProps} />}
              {showConfirmationModal &&
                <ConfirmationModal {...confirmationModalProps} />
              }
              {loadingBtnAction && <ActionLoader />}
              <motion.div className="scrollTable" variants={childVariants}>
                <BootstrapTable
                  bordered={false}
                  headerClasses="custom-header-class"
                  rowClasses="custom-row-class"
                  bodyClasses="custom-body-class"
                  selectRow={
                    tabSelected === "all" || tabSelected === "issue"
                      ? noSelection
                      : selectRowModes
                  }
                  sort={{ dataField: "date", order: "desc" }}
                  {...paginationTableProps}
                  {...props.baseProps}
                />
              </motion.div>
            </div>
          </motion.div>
        )}
      </ToolkitProvider>
    </div>
  );
  return (
    <>
      {isLoading ? (
        <>
          <Skeleton count={1} height={70} style={{ marginBottom: "10px" }} />
          <Skeleton count={1} height={30} style={{ marginBottom: "10px" }} />
          <Skeleton count={4} height={50} style={{ marginBottom: "10px" }} />
        </>
      ) : (
        <PaginationProvider pagination={paginationFactory(options)}>
          {contentTable}
        </PaginationProvider>
      )}
    </>
  );
}
