import React, { useEffect, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { fadeInAndUpNoDelay } from '../../../services/shared/animation.service';
import { motion } from 'framer-motion';
import { ButtonSmall } from '../../units/Button';
import { Abstract } from './Abstract';
import { ClientInfoInputs } from './ClientInfoInputs';
import { DeliveryInfo } from './DeliveryInfo';
import { getClientDetail, saveClientInfo } from '../../../services/panel.service';
import { showError, showSuccess } from '../../../services/shared/alert.service';
import { validateInputs } from '../../../services/shared/utils.service';

export const MoreInfoModal = ({ handleClose, orderId, storeId, orderData }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [previousData, setPreviousData] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [accordionItems, setAccordionItems] = useState({
        first: true,
        second: false,
        third: false
    })

    useEffect(() => {
        updateDeliveryInfo();
    }, []);

    useEffect(() => {
        if(Object.keys(formErrors).length > 0){
            setAccordionItems({
                first: false,
                second: true,
                third: false
            });
        }
    }, [formErrors]);

    const [body, setBody] = useState({
        fullName: "",
        phoneNumber: "",
        notes: "",
        address: "",
        email: "",
    });

    const updateDeliveryInfo = async () => {
        try {
            setIsLoading(true)
            const result = await getClientDetail(orderId, storeId);
            if (result && result?.success) {
                setPreviousData(result)
                setBody(result?.client)
            }
            if (result.error) {
                showError(result.error);
            }
            setIsLoading(false)
        } catch (error) {
            showError(error)
            setIsLoading(false)
        }
    }

    const handleChange = (e) => {
        setBody({ ...body, [e.target.id]: e.target.value });
    }

    const handleSelectChange = (e) => {
        setPreviousData({ ...previousData, packageSize: e.target.value });
    }

    const saveDeliveryInfo = async (e) => {
        e.preventDefault();
        if (validateInputs(body, setFormErrors)) {
            setIsLoading(true);
            let composedBody = {...previousData, client: { ...body }, orderId: orderId};
            try {
                const result = await saveClientInfo(composedBody, storeId);
                if (result && result.success) {
                    showSuccess(result.message);
                    handleClose();
                }
                if (result.error) {
                    showError(result.error);
                }
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                showError(error);
            }
        } else {
            return
        }
    }

    return (
        <Modal
            className="rounded-3"
            id="panel-moreInfo-modal"
            show={true}
            backdrop={true}
            onHide={handleClose}
            centered
            size="lg"
            scrollable
        >
            <Modal.Header closeButton className="modal-header border-0">
                <Modal.Title className="modal-title">Más información</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                {!isLoading &&
                    <motion.div variants={fadeInAndUpNoDelay} initial="hidden" animate="visible">

                        <Abstract accordionItems={accordionItems} setAccordionItems={setAccordionItems} requestedInfo={previousData} handleSelectChange={handleSelectChange}/>

                        <ClientInfoInputs accordionItems={accordionItems} setAccordionItems={setAccordionItems} body={body} handleChange={handleChange} formErrors={formErrors} />

                        <DeliveryInfo accordionItems={accordionItems} setAccordionItems={setAccordionItems} availableInfo={orderData} requestedInfo={previousData} />

                    </motion.div>
                }
            </Modal.Body>
            <Modal.Footer className="modal-footer d-flex justify-content-between border-0">
                <ButtonSmall
                    type="button"
                    btnTxt="Cerrar"
                    showSpinner={false}
                    isDisabled={false}
                    onClickFn={() => handleClose()}
                    extraClass="btn btn-outline-primary"
                />
                <ButtonSmall
                    type="button"
                    btnTxt="Guardar"
                    showSpinner={false}
                    isDisabled={(body.fullName && body.phoneNumber && body.address && body.email) ? false : true}
                    onClickFn={saveDeliveryInfo}
                    extraClass="btn btn-primary"
                />
            </Modal.Footer>
        </Modal>
    )
}
