import React, { useState } from 'react'
import DatePicker from 'react-datepicker'

export const GeneralHoursSchedule = ({ weeks, setWeeks,  startDate, endDate, setStartDate, setEndDate }) => {
    const [timeErrors, setTimeErrors] = useState(false);

    const handleGeneralStartDate = (startDate) => {
        const resetWeekdays = weeks.map((day) => (
            { ...day, openingHour: 0, openingMinute: 0, closingHour: 0, closingMinute: 0 }
        ))
        setStartDate(startDate);
        let modifiedWeeks = resetWeekdays.map((day) => {
            if (day.open) {
                return { ...day, openingHour: startDate.getHours(), openingMinute: startDate.getMinutes() }
            } else {
                return day
            }
        })
        setWeeks(modifiedWeeks);
    }

    const handleGeneralEndDate = (endDate) => {
        if (startDate > endDate) {
            setTimeErrors(true);
            setEndDate(null)
        } else {
            setTimeErrors(false);
            setEndDate(endDate);
            let modifiedWeeks = weeks.map((day) => {
                if (day.open) {
                    return { ...day, closingHour: endDate.getHours(), closingMinute: endDate.getMinutes() }
                } else {
                    return day
                }
            })
            setWeeks(modifiedWeeks);
        }
    }

    return (
        <div className='week-hours-container general'>
            <p>Días seleccionados</p>
            <div className='personalized-week-hours-wrapper'>
                <p>de</p>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => handleGeneralStartDate(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    excludeTimes={[
                        new Date().setHours(0, 0, 0),
                        new Date().setHours(0, 30, 0),
                    ]}
                    timeCaption="Time"
                    dateFormat="hh:mm aa"
                    className="custom-picker-input"
                    wrapperClassName="custom-picker-wrapper"
                    placeholderText="Ej. 13:00"
                />
                <p>a</p>
                <DatePicker
                    selected={endDate}
                    onChange={(date) => handleGeneralEndDate(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    excludeTimes={[
                        new Date().setHours(0, 0, 0),
                        new Date().setHours(0, 30, 0),
                    ]}
                    timeCaption="Time"
                    dateFormat="hh:mm aa"
                    className="custom-picker-input"
                    placeholderText="Ej. 18:00"
                />
                {timeErrors &&
                    <p className='invalid-msg'>El horario de finalización debe ser posterior al de inicio</p>
                }
            </div>
        </div>
    )
}
