import { Route, Switch } from "react-router-dom";
import { Credentials } from "../../../pages/onboarding/Credentials";
import { LocationsList } from "../../../pages/onboarding/LocationsList";
import { LocationSingle } from "../../../pages/onboarding/LocationSingle";
import { Rates } from "../../../pages/onboarding/Rates";
import { Configurations } from "../../../pages/onboarding/Configurations";
import { Finish } from "../../../pages/onboarding/Finish";
import PanelRoute from "../panel/panelRoute";
import LabelsConfirmation from "../../../components/labels";

export default function CustomerShopifyRoute({ rootCustomerPath }) {
  return (
    <>
        <Switch>
            <Route exact path={`/${rootCustomerPath}/secommerce/credentials`} component={Credentials} />
            <Route exact path={`/${rootCustomerPath}/secommerce/location-list`} component={LocationsList} />
            <Route exact path={`/${rootCustomerPath}/secommerce/location-list/:id`} component={LocationSingle} />
            <Route exact path={`/${rootCustomerPath}/secommerce/rates`} component={Rates} />
            <Route exact path={`/${rootCustomerPath}/secommerce/package`} component={Configurations} />
            <Route exact path={`/${rootCustomerPath}/secommerce/finish`} component={Finish} />

            <Route path={`/${rootCustomerPath}/secommerce/panel`} render={() => <PanelRoute rootCustomerPath={rootCustomerPath} /> }/> 
            
            <Route exact path={`/${rootCustomerPath}/secommerce`} component={Credentials} />

            <Route exact path={`/${rootCustomerPath}/secommerce/labels`} component={LabelsConfirmation} />
        </Switch>
    </>
  );
}
