import {
  post as restPost,
  get as restGet,
} from '../services/shared/rest.service';
import { mockedLocations, fee, vehicle } from '../data/mockData';

//amplitude analytics
import { track } from '@amplitude/analytics-browser';

//only for developing purposes
const devMode = false;

//compartidos
export const loginService = async (params, storeId, userId) => {
  try {
    const endpoint = `/embedded/onboarding/authentication?shop=${storeId}`;
    const body = {
      apiKey: params.customerApiKey,
      apiSecret: params.customerApiToken,
      userId: userId || '',
    };
    const result = await restPost(endpoint, body);
    return result.data;
  } catch (error) {
    throw error.message;
  }
};

export const getLocations = async (storeId) => {
  if (!devMode) {
    try {
      const endpoint = `/embedded/onboarding/locations?shop=${storeId}`;
      const result = await restGet(endpoint);
      return result.data;
    } catch (error) {
      throw error.message;
    }
  } else {
    return mockedLocations;
  }
};

export const verifyNewLocation = async (params, storeId) => {
  try {
    const endpoint = `/embedded/onboarding/validate-address?shop=${storeId}`;
    const result = await restPost(endpoint, params);
    return result.data;
  } catch (error) {
    throw error.message;
  }
};

export const configSingleLocation = async (params, storeId) => {
  try {
    const endpoint = `/embedded/onboarding/location?shop=${storeId}`;
    const result = await restPost(endpoint, params);
    return result.data;
  } catch (error) {
    throw error.message;
  }
};

export const saveLocationsData = async (params, storeId) => {
  try {
    const endpoint = `/embedded/onboarding/locations?shop=${storeId}`;
    const result = await restPost(endpoint, params);
    return result.data;
  } catch (error) {
    throw error.message;
  }
};

export const getRates = async (storeId) => {
  if (!devMode) {
    try {
      const endpoint = `/embedded/onboarding/fee?shop=${storeId}`;
      const result = await restGet(endpoint);
      return result.data;
    } catch (error) {
      throw error.message;
    }
  } else {
    return fee;
  }
};

export const saveRates = async (params, storeId) => {
  try {
    const endpoint = `/embedded/onboarding/fee?shop=${storeId}`;
    const result = await restPost(endpoint, params);
    return result.data;
  } catch (error) {
    throw error.message;
  }
};

export const getPackage = async (storeId) => {
  if (!devMode) {
    try {
      const endpoint = `/embedded/onboarding/package?shop=${storeId}`;
      const result = await restGet(endpoint);
      return result.data;
    } catch (error) {
      throw error.message;
    }
  } else {
    return vehicle;
  }
};

export const savePackage = async (params, storeId) => {
  try {
    const endpoint = `/embedded/onboarding/package?shop=${storeId}`;
    const result = await restPost(endpoint, params);
    return result.data;
  } catch (error) {
    throw error.message;
  }
};

// Others

export const checkConfiguration = (
  locationData,
  setAllActiveLocationsConfigurated,
  setbtnMessage
) => {
  let activeLocations = locationData.filter(
    (loc) => loc.availableForDelivery === true
  );
  if (activeLocations && activeLocations.length !== 0) {
    if (activeLocations.length > 1) {
      if (
        activeLocations.every(
          (loc) =>
            loc.manager && loc.phoneNumber && loc.isEveryOpenDayConfigurated
        )
      ) {
        setAllActiveLocationsConfigurated(true);
      } else {
        setAllActiveLocationsConfigurated(false);
        setbtnMessage(`Configura todas tus tiendas activas para continuar`);
      }
    } else {
      if (
        activeLocations[0]?.manager &&
        activeLocations[0]?.phoneNumber &&
        activeLocations[0]?.isEveryOpenDayConfigurated
      ) {
        setAllActiveLocationsConfigurated(true);
      } else {
        setAllActiveLocationsConfigurated(false);
        setbtnMessage(`Configura todas tus tiendas activas para continuar`);
      }
    }
  } else {
    setAllActiveLocationsConfigurated(false);
    setbtnMessage('Debes activar al menos uno para continuar');
  }
};

export const requestBodySetter = (
  typeOfFee,
  body,
  promoFreeShipping,
  promoDynamicFee,
  radioValue,
  serviceType
) => {
  let composedBody = {
    data: {
      fee: {
        type: typeOfFee,
        amount: Number(body.fee),
        promotionalFreeShipping: {
          active: promoFreeShipping,
          amount: !promoFreeShipping ? 0 : Number(body.promoFreeShipAmount),
        },
        promotionalDynamicFee: {
          active: promoDynamicFee,
          amount: !promoDynamicFee
            ? 0
            : Number(`${radioValue}${body.promoDynFeeAmount}`),
        },
      },
      serviceType: serviceType,
    },
  };
  return composedBody;
};

export const timeOptions = [
  {
    label: '30min',
    value: 30,
  },
  {
    label: '1hs',
    value: 60,
  },
  {
    label: '3hs',
    value: 180,
  },
  {
    label: '1 día',
    value: 1440,
  },
  {
    label: '2 días',
    value: 2880,
  },
  {
    label: '3 días',
    value: 4320,
  },
];

export const checkForEnablingButton = (
  weekDays,
  setAllCompleted,
  preparationTime
) => {
  let openDays = weekDays.filter((days) => days.open);
  let everyOpenDayConfigurated = openDays.every(
    (openDay) => openDay.openingHour && openDay.closingHour
  );
  if (preparationTime && openDays.length > 0) {
    if (everyOpenDayConfigurated) {
      setAllCompleted(true);
    } else {
      setAllCompleted(false);
    }
  } else {
    setAllCompleted(false);
  }
};

//amplitude analytics
export const amplitudeTrack = (
  eventType,
  storeId,
  eventProps,
  extraProps = {}
) => {
  const fullProps = { ...eventProps, client_id: storeId, ...extraProps };
  track(eventType, fullProps);
  console.log(eventType, fullProps);
};
