import React from 'react'
import { InputsSwitch } from '../../../units/Inputs'
import thunderbolt from '../../../../assets/icons/thunderbolt.png';

export const AutomaticSetter = ({ isAutomatic, setIsAutomatic }) => {
    return (
        <div>
            <div className='illustration-and-text'>
                <div className="illustration-wrapper">
                    <img src={thunderbolt} alt="thunderbolt" />
                </div>
                <div className="text-wrapper">
                    <h3 className='intro-subtitle'>Creación de envíos automático</h3>
                    <p className='small-subtitle'>En el modo automático tus envíos se crearán con Cabify sin hacer ninguna acción.</p>
                </div>
            </div>
            <InputsSwitch
                id="isAutomatic"
                value={isAutomatic}
                checked={isAutomatic}
                onChangeFn={() => setIsAutomatic(!isAutomatic)}
                labelTxt=""
            />
        </div>
    )
}
