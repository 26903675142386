import { mockedEvidenceInfo, mockedMoreInfo, mockedOrders } from '../data/mockData';
import { post as restPost, get as restGet } from '../services/shared/rest.service';
import { convertBase64ToBlob } from './grid.service';
import { showError } from './shared/alert.service';

//only for developing purposes
const devMode = false;

export const getOrders = async (storeId = "") => {
    if (!devMode) {
        try {
            const endpoint = `/embedded/panel/orders?shop=${storeId}`;
            const result = await restGet(endpoint);
            return result.data;
        } catch (error) {
            throw error.message
        }
    } else {
        return mockedOrders
    }
};

const setTab = (status) => {
    if (status==="pending"){
        return "pending"
    }
    if (status==="ready" || status==="qualifiedforpickup" || status==="onroutetopickup" || status==="pickingup" || status==="intransit" || status==="delivering" || status==="availableForCollect" || "readytopickup" || "onroutetofinalhub" || "receivedonfinalhub" || "readytodispatch" || "onroutetodelivery"){
        return "created"
    }
    if (status==="delivered"){
        return "delivered"
    }
    if (status==="returning" || status==="returned" || status==="incident" || status==="requestercanceled" || status==="internalcanceled" || status==="pickupfailed" || status==="cancelled" || status==="error"){
        return "issue"
    }
};

const setTooltipMessage = (status, errorPhrase) => {
    const messagesList = {
        //customer's messages
        "ready": "Creamos tu envío",
        "readytopickup": "El conductor asignado colectará tu paquete",    
        "onroutetofinalhub": "El conductor se encuentra en el final del recorrido",    
        "receivedonfinalhub": "Tu paquete ha sido recibido por tu comprador",   
        "readytodispatch": "Tu paquete está listo para ser enviado",  
        "qualifiedforpickup": "Estamos buscando un conductor para este viaje",
        "onroutetopickup": "El conductor esta en camino a tu tienda para buscar el paquete",
        "pickingup": "El conductor se encuentra en tu tienda, esta esperando recibir el paquete",
        "intransit": "El paquete se encuentra en camino al domicilio de entrega",
        "onroutetodelivery": "El conductor está en camino para realizar la entrega",    
        "delivering": "El conductor se encuentra en destino, esta esperando que recojan el paquete",
        "delivered": "El paquete ha sido entregado",
        "returning": "El conductor esta volviendo a tu tienda con el paquete, no pudo entregarlo",
        "returned": "El conductor no pudo entregar el paquete y lo ha devuelto a la tienda",
        "incident": "Ocurrió un problema durante el proceso de devolución del paquete",
        "requestercancel": "La tienda canceló el envío",
        "internalcancel": "Hemos cancelado tu envío, por favor ponte en contacto con nosotros",
        "pickupfailed": "No pudimos recoger tu paquete, por favor ponte en contacto con nosotros",

        //conexa's messages:
        "pending": "Crea tu orden para solicitar un envío",
        "cancelled": "Tu envío fue cancelado manualmente",
        "error": errorPhrase || "Ocurrió un error",
        "restored": "Tu envío ha sido restaurado correctamente",
    };
    return messagesList[status];
};

const setTranslation = (status) => {
    const translations = {
        //customer's status
        "ready": "Creado",
        "readytopickup": "Listo para retirar",    
        "onroutetofinalhub": "Llegando al destino",    
        "receivedonfinalhub": "Recepcionado en el destino",   
        "readytodispatch": "Listo para enviar",  
        "qualifiedforpickup": "Buscando Conductor",
        "onroutetopickup": "Por Colectar",
        "pickingup": "Esperando el paquete",
        "intransit": "Esperando el paquete",
        "onroutetodelivery": "En camino a la entrega",  
        "delivering": "Esperando el paquete",
        "delivered": "Entregado",
        "returning": "En devolución",
        "returned": "Devuelto",
        "incident": "Ocurrió un problema",
        "requestercancel": "Envío Cancelado",
        "internalcancel": "Envío Cancelado",
        "pickupfailed": "Envío Cancelado",

        //conexa's status:
        "pending": "Pendiente de envío",
        "cancelled": "Cancelada",
        "error": "Error",
        "restored": "Restaurada",
    }
    return translations[status];
};

export const handleOrdersList = async (data) => {
    console.log(data);
    let formattedOrders = [];
    let key = 0;
    for (const order of data) {
        key++;
        formattedOrders.push({
            key: key,
            ecommerceNumber: order.orderNumber,
            orderId: order.orderId,
            shopifyOrderId: order.shopifyOrderId,
            originalStatus: order.trackingStatus,
            status: setTranslation(order.trackingStatus),
            date: order.date,
            trackingUrl: order.trackingUrl,
            label: order.label,
            creationDate: order.date,
            error: order.error,
            overallTab: setTab(order.trackingStatus),
            message: setTooltipMessage(order.trackingStatus, order.error),
            origin: order.origin,
            client: order.client,
            costValue: order.shippingCostDelivery || null,
        })
    }
    console.log(formattedOrders);
    return formattedOrders
}


// Order actions

export const createOrder = async (ordersArr, storeId = "") => {
    try {
        const endpoint = `/embedded/panel/fulfill-orders?shop=${storeId}`;
        const result = await restPost(endpoint, ordersArr);

        return result.data;
    } catch (error) {
        throw error.message
    }
};

export const deliverOrder = async (ordersArr, storeId = "") => {
    try {
        const endpoint = `/panel/request-collect?shop=${storeId}`;
        const result = await restPost(endpoint, ordersArr);

        return result.data;
    } catch (error) {
        throw error.message
    }
};

export const deleteOrder = async (ordersArr, storeId = "") => {
    try {
        const endpoint = `/embedded/panel/cancel-orders?shop=${storeId}`;
        const result = await restPost(endpoint, ordersArr);

        return result.data;
    } catch (error) {
        throw error.message
    }
};

export const cancelOrder = async (ordersArr, storeId = "") => {
    try {
        const endpoint = `/embedded/panel/cancel-deliveries?shop=${storeId}`;
        const result = await restPost(endpoint, ordersArr);

        return result.data;
    } catch (error) {
        throw error.message
    }
};

export const retryOrder = async (ordersArr, storeId = "") => {
    try {
        const endpoint = `/embedded/panel/repeat-orders?shop=${storeId}`;
        const result = await restPost(endpoint, ordersArr);

        return result.data;
    } catch (error) {
        throw error.message
    }
};

export const restoreSingleOrder = async (ordersArr, storeId = "") => {
    try {
        const endpoint = `/embedded/panel/restore-orders?shop=${storeId}`;
        const result = await restPost(endpoint, ordersArr);

        return result.data;
    } catch (error) {
        throw error.message
    }
};

export const getOrderTicket = async (ordersArr, storeId = "") => {
    try {
        const endpoint = `/panel/download-labels?shop=${storeId}`;
        const result = await restPost(endpoint, ordersArr);

        return result.data;
    } catch (error) {
        throw error.message
    }
};

export const getCostOfDelivery = async (rowId, storeId = "") => {
    try {
        const endpoint = `/embedded/panel/calculate-cost?shop=${storeId}`;
        const result = await restPost(endpoint, rowId);

        return result.data;
    } catch (error) {
        throw error.message
    }
};

export const printLabel = async (ordersToPrint, storeId = "", setIsLoading, forceFinish = true, throwError = false) => {
    const ordersArr = Array.isArray(ordersToPrint) ? ordersToPrint : [ordersToPrint];
    const encodedOrdersArr = encodeURIComponent(JSON.stringify(ordersArr))
    const endpoint = `/embedded/panel/print-label?shop=${storeId}&orders=${encodedOrdersArr}`;
    setIsLoading(true)
    try {
        const response = await restGet(endpoint)
        if (response.data.data) {
            const deliberyLabel = await convertBase64ToBlob(response.data.data);
            const blobUrl = URL.createObjectURL(deliberyLabel);
            const tempLinkElement = document.createElement("a");
            tempLinkElement.href = blobUrl;
            tempLinkElement.download = "label"; // Set the desired file name
            tempLinkElement.style.display = "none";
            document.body.appendChild(tempLinkElement);
            tempLinkElement.click();
            document.body.removeChild(tempLinkElement);
        }
        !forceFinish && setIsLoading(false);        
    } catch (error) {
        showError('No se obtuvieron etiquetas para la orden seleccionada.');
        if (throwError) {
            throw error;
        }
    } finally {
        forceFinish && setIsLoading(false);
    }
};

// 'more info' modal

export const getClientDetail = async (orderId, storeId = "") => {
    if(!devMode){
        try {
            const endpoint = `/embedded/panel/order-detail?shop=${storeId}&orderId=${orderId}`;
            const result = await restGet(endpoint);
    
            return result.data;
        } catch (error) {
            throw error.message
        }
    } else {
        return mockedMoreInfo
    }
}

export const saveClientInfo = async (params, storeId = "") => {
    try {
        const endpoint = `/embedded/panel/edit-order?shop=${storeId}`;
        const result = await restPost(endpoint, params);
        return result.data;

    } catch (error) {
        throw error.message
    }
}

// evidence display modal

export const getEvidenceInfo = async (orderId, storeId = "") => {
    if(!devMode){
        try {
            const endpoint = `/panel/order-info?shop=${storeId}&orderId=${orderId}`;
            const result = await restGet(endpoint);
    
            return result.data;
        } catch (error) {
            throw error.message
        }
    } else {
        return mockedEvidenceInfo
    }
}

// client's settings for displaying orders according to method

export const getOrdersSetting = async (storeId = "") => {
    try {
        const endpoint = `/embedded/panel/configuration?shop=${storeId}`;
        const result = await restGet(endpoint);

        return result.data;
    } catch (error) {
        throw error.message
    }
};

export const setOrdersSetting = async (body, storeId = "") => {
    try {
        const endpoint = `/embedded/panel/configuration?shop=${storeId}`;
        const result = await restPost(endpoint, body);

        return result.data;
    } catch (error) {
        throw error.message
    }
};