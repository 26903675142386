import React from 'react';
import { ButtonSmall } from '../units/Button';

export const TableMassiveBtns = ({ tabSelected, ...massiveBtnProps }) => {
    return (
        <div className="massive-btn-wrapper">
            {tabSelected === "pending" && (
                <ButtonSmall
                    type="button"
                    btnTxt={`Solicitar repartidor (${massiveBtnProps.ordersSelected.length})`}
                    showSpinner={false}
                    isDisabled={false}
                    onClick={() => {
                        massiveBtnProps.setConfirmationModalProps({
                            handleClose: () => massiveBtnProps.setShowConfirmationModal(false),
                            bodyText: "¿Solicitar repartidor?",
                            mainTask: () => massiveBtnProps.setOrdersAction(massiveBtnProps.ordersSelected, massiveBtnProps.auth?.user?.storeId, tabSelected, massiveBtnProps.setIsLoading, massiveBtnProps.updateTable, "fulfill", massiveBtnProps.resetOrdersSelections)
                        })
                        massiveBtnProps.setShowConfirmationModal(true)
                    }}
                    extraClass="grid-fixed-size full"
                />
            )}
            {(tabSelected === "created") && (
                <>
                    <ButtonSmall
                        type="button"
                        btnTxt={`Cancelar (${massiveBtnProps.ordersForCancel.length})`}
                        showSpinner={false}
                        isDisabled={false}
                        onClick={() => {
                            massiveBtnProps.setConfirmationModalProps({
                                handleClose: () => massiveBtnProps.setShowConfirmationModal(false),
                                bodyText: "¿Estás seguro que deseas cancelar los envíos en curso? Esta acción podría tener costos extra",
                                mainTask: () => massiveBtnProps.setOrdersAction(massiveBtnProps.ordersForCancel, massiveBtnProps.auth?.user?.storeId, tabSelected, massiveBtnProps.setIsLoading, massiveBtnProps.updateTable, "cancel", massiveBtnProps.resetOrdersSelections)
                            })
                            massiveBtnProps.setShowConfirmationModal(true)
                        }}
                        extraClass="grid-fixed-size full"
                    />
                    <ButtonSmall
                        type="button"
                        btnTxt={`Imprimir (${massiveBtnProps.ordersSelected.length})`}
                        showSpinner={false}
                        isDisabled={false}
                        onClickFn={() => {
                            massiveBtnProps.printTickets(massiveBtnProps.ordersSelected, massiveBtnProps.setIsLoading, massiveBtnProps.auth?.user?.storeId);
                            massiveBtnProps.setOrdersSelected([])
                        }}
                        extraClass="grid-fixed-size outlined"
                    />
                </>
            )}
            {(tabSelected === "delivered") && (
                <>
                    <ButtonSmall
                        type="button"
                        btnTxt={`Volver a solicitar repartidor (${massiveBtnProps.ordersSelected.length})`}
                        showSpinner={false}
                        isDisabled={false}
                        onClick={() => {
                            massiveBtnProps.setConfirmationModalProps({
                                handleClose: () => massiveBtnProps.setShowConfirmationModal(false),
                                bodyText: "¿Volver a solicitar repartidor?",
                                mainTask: () => massiveBtnProps.setOrdersAction(massiveBtnProps.ordersSelected, massiveBtnProps.auth?.user?.storeId, tabSelected, massiveBtnProps.setIsLoading, massiveBtnProps.updateTable, "retry", massiveBtnProps.resetOrdersSelections)
                            })
                            massiveBtnProps.setShowConfirmationModal(true)
                        }}
                        extraClass="grid-fixed-size full"
                    />
                    <ButtonSmall
                        type="button"
                        btnTxt={`Imprimir (${massiveBtnProps.ordersSelected.length})`}
                        showSpinner={false}
                        isDisabled={false}
                        onClickFn={() => {
                            massiveBtnProps.printTickets(massiveBtnProps.ordersSelected, massiveBtnProps.setIsLoading, massiveBtnProps.auth?.user?.storeId);
                            massiveBtnProps.setOrdersSelected([])
                        }}
                        extraClass="grid-fixed-size outlined"
                    />
                </>
            )}
        </div>
    )
}
