import React, { useEffect } from 'react';
import { ButtonSmall } from '../../../units/Button';
import { InputsSimple } from '../../../units/Inputs';
import { FiChevronLeft } from "react-icons/fi";

export const NewLocInfoForm = ({ newAddressInfo, setNewAddressInfo, formErrors, resetNewAddress, setIsFormComplete }) => {

    useEffect(() => {
        console.log(newAddressInfo);
        let condition = newAddressInfo.address && newAddressInfo.city && newAddressInfo.province && newAddressInfo.country && newAddressInfo.zip && newAddressInfo.name && newAddressInfo.phoneNumber;
        if (condition) {
            setIsFormComplete(true);
        } else {
            setIsFormComplete(false);
        }
    }, [newAddressInfo])

    const handleChange = (e) => {
        setNewAddressInfo({ ...newAddressInfo, [e.target.id]: e.target.value })
    }

    return (
        <>
            <div className="form-grid-two-columns">
                <>
                    <InputsSimple
                        value={newAddressInfo?.address || ""}
                        id="address"
                        type="text"
                        placeholder="Número y calle"
                        labelTxt="Dirección*"
                        onChangeFn={handleChange}
                        resultValidation={formErrors?.address ? "is-invalid" : ""}
                        errorTxt={false}
                    />

                    <InputsSimple
                        value={newAddressInfo?.city || ""}
                        id="city"
                        type="text"
                        placeholder=""
                        labelTxt="Ciudad*"
                        onChangeFn={handleChange}
                        resultValidation={formErrors?.city ? "is-invalid" : ""}
                        errorTxt={false}
                    />

                    <InputsSimple
                        value={newAddressInfo?.province || ""}
                        id="province"
                        type="text"
                        placeholder=""
                        labelTxt="Provincia / Estado*"
                        onChangeFn={handleChange}
                        resultValidation={formErrors?.province ? "is-invalid" : ""}
                        errorTxt={false}
                    />
                </>

                <>
                    <InputsSimple
                        value={newAddressInfo?.country || ""}
                        id="country"
                        type="text"
                        placeholder=""
                        labelTxt="País*"
                        onChangeFn={handleChange}
                        resultValidation={formErrors?.country ? "is-invalid" : ""}
                        errorTxt={false}
                    />
                    <InputsSimple
                        value={newAddressInfo?.zip || ""}
                        id="zip"
                        type="text"
                        placeholder=""
                        labelTxt="Código postal*"
                        onChangeFn={handleChange}
                        resultValidation={formErrors?.zip ? "is-invalid" : ""}
                        errorTxt={false}
                    />

                    <InputsSimple
                        value={newAddressInfo?.name || ""}
                        id="name"
                        type="text"
                        placeholder=""
                        labelTxt="Nombre de la tienda*"
                        onChangeFn={handleChange}
                        resultValidation={formErrors?.name ? "is-invalid" : ""}
                        errorTxt={false}
                    />

                    <InputsSimple
                        value={newAddressInfo?.phoneNumber || ""}
                        id="phoneNumber"
                        type="text"
                        placeholder=""
                        labelTxt="Teléfono*"
                        onChangeFn={handleChange}
                        resultValidation={formErrors?.phoneNumber ? "is-invalid" : ""}
                        errorTxt={false}
                    />
                </>
            </div>

            <div className='goBack-container'>
                <div className="goBack-wrapper">
                    <FiChevronLeft />
                    <ButtonSmall
                        type="button"
                        btnTxt="Volver al mapa"
                        onClickFn={resetNewAddress}
                        showSpinner={false}
                        isDisabled={false}
                        extraClass="btn-link"
                    />
                </div>
            </div>
        </>
    )
}
