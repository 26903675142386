import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;


export const post = async (endpoint, body, headers, responseType) => {

    try {
        const url = `${baseUrl}${endpoint}`;

        const result = await axios(url, {
            method: 'POST',
            headers: headers ? headers : {'content-type': 'application/json'},
            data: body,
            responseType: responseType || null
        });
        return result;

    } catch (error) {
        const message = error?.response?.data?.error || "Ocurrió un error en la conexión";
        throw new Error(message)
    }
}

export const get = async (endpoint, headers) => {

    try {
        const url = `${baseUrl}${endpoint}`;
        const result = await axios(url, {  
            method: 'GET',
            headers: headers ? headers : { 'content-type': 'application/json' }
        });
        return result;

    } catch (error) {
        const message = error?.response?.data?.error || "Ocurrió un error en la conexión.";
        throw new Error(message)
    }
}

export const put = async (endpoint, body, headers, responseType) => {

    try {
        const url = `${baseUrl}${endpoint}`;
        const result = await axios(url, {  
            method: 'PUT',
            headers: headers ? headers : { 'content-type': 'application/json' },
            data: body,
            responseType: responseType || null
        });
        return result;

    } catch (error) {
        const message = error?.response?.data?.error || "Ocurrió un error en la conexión.";
        throw new Error(message)
    }
}