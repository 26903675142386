import React from 'react';
import { childVariants } from '../../../services/shared/animation.service';
import { motion } from 'framer-motion';
import { InputsRadio, InputsWithTooltip } from '../../units/Inputs';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const RatesFormInputs = ({ ...basicInputsProps }) => {

    const handleRadioChange = (e) => {
        if (e.target.checked) {
            basicInputsProps.setTypeOfFee(e.target.value);
        }
        if (e.target.id === "free") {
            basicInputsProps.setBody({ fee: 0, promoDynFeeAmount: 0, promoFreeShipAmount: 0 });
            basicInputsProps.setPromoFreeShipping(false);
            basicInputsProps.setPromoDynamicFee(false);
        }
        if (e.target.id === "dynamic") {
            basicInputsProps.setBody({ ...basicInputsProps.body, fee: 0, promoFreeShipAmount: 0 });
            basicInputsProps.setPromoFreeShipping(false);
        }
        if (e.target.id === "fixed") {
            if (basicInputsProps.store?.currentlocations?.fee?.amount && basicInputsProps.store?.currentlocations?.fee?.amount > 0) {
                basicInputsProps.setBody({ ...basicInputsProps.body, fee: basicInputsProps.store.currentlocations.fee.amount, promoDynFeeAmount: 0 });
                basicInputsProps.setPromoDynamicFee(false);
            } else {
                basicInputsProps.setBody({ ...basicInputsProps.body, fee: 0, promoDynFeeAmount: 0 });
                basicInputsProps.setPromoDynamicFee(false);
            }
        }
    }

    const handleChange = (e) => {
        basicInputsProps.setBody({ ...basicInputsProps.body, [e.target.id]: Number(e.target.value) });
        basicInputsProps.checkPromoValidation()
    };

    return (
        <motion.div className='mt-2 mt-xxl-3' variants={childVariants}>
            <InputsRadio
                id="dynamic"
                name="typeOfFee"
                value="dynamic"
                labelTxt={`Tarifa dinámica de ${basicInputsProps.customerName}.`}
                onChangeFn={handleRadioChange}
                checked={basicInputsProps.typeOfFee === "dynamic"}
            />
            <InputsRadio
                id="fixed"
                name="typeOfFee"
                value="fixed"
                labelTxt="Tarifa fija."
                onChangeFn={handleRadioChange}
                checked={basicInputsProps.typeOfFee === "fixed"}
            />
            {basicInputsProps.typeOfFee === "fixed" &&
                <div className='feeFixed-phrase-shop'>
                    <span className="form-check-label">Costo de envío de $</span>
                    <InputsWithTooltip
                        value={String(basicInputsProps.body.fee).replace(/^0+/, '')}
                        id="fee"
                        type="number"
                        onChangeFn={handleChange}
                        min={0}
                    />
                    {basicInputsProps.formErrors.fee && <span className="invalid-msg">{basicInputsProps.formErrors.fee}</span>}
                </div>
            }
            <InputsRadio
                id="free"
                name="typeOfFee"
                value="free"
                labelTxt="Tarifa gratis."
                onChangeFn={handleRadioChange}
                checked={basicInputsProps.typeOfFee === "free"}
            />
        </motion.div>
    )
}

export const RatesSkeleton = ({ auth }) => {
    return (
        <>
            <Skeleton count={1} height={60} style={{ marginBottom: "20px" }} />
            <Skeleton count={1} height={20} style={{ marginBottom: "5px" }} />
            <Skeleton count={1} height={20} style={{ marginBottom: "5px" }} />
            <Skeleton count={1} height={20} style={{ marginBottom: "5px" }} />
            {auth?.user?.insidePanel &&
                <Skeleton count={1} height={100} style={{ marginTop: "20px" }} />
            }
        </>
    )
}
