import React from 'react';
import { motion } from 'framer-motion';
import { growBtn } from '../../../../services/shared/animation.service';
import { ButtonSmall } from '../../../units/Button';

export const LocTimesAndMetButtons = ({ ecommerce, goNextStage, isLoading, allCompleted }) => {
    return (
        <div className="bottomPage doubleBtn">
            <motion.div variants={growBtn} initial="hidden" animate="visible">
                <ButtonSmall
                    ecommerce={ecommerce}
                    type="button"
                    btnTxt="Atrás"
                    onClickFn={() => goNextStage(1)}
                    showSpinner={isLoading}
                    extraClass="btn-outline-primary"
                    isDisabled={false}
                />
            </motion.div>
            <motion.div variants={growBtn} initial="hidden" animate="visible">
                <ButtonSmall
                    ecommerce={ecommerce}
                    type="submit"
                    btnTxt="Guardar"
                    showSpinner={isLoading}
                    isDisabled={allCompleted ? false : true}
                />
            </motion.div>
        </div>
    )
}
