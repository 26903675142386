import React from 'react';
import { childVariants } from '../../../services/shared/animation.service';
import { motion } from 'framer-motion';
import { InputsRadio } from '../../units/Inputs';

export const PackagesInputs = ({ setPackageSize, packageSize }) => {

    const handlePackageSelection = (e) => {
        if (e.target.checked) {
            setPackageSize(e.target.value);
        }
    }

    return (
        <motion.ul className='packageRadio-container my-2 my-xl-3' variants={childVariants}>
            <li>
                <InputsRadio
                    id="S"
                    name="packageSize"
                    value="S"
                    labelTxt="Tamaño S"
                    onChangeFn={handlePackageSelection}
                    checked={packageSize === "S"}
                />
                <p className='small-subtitle'>Hasta un libro.</p>
            </li>
            <li>
                <InputsRadio
                    id="M"
                    name="packageSize"
                    value="M"
                    labelTxt="Tamaño M"
                    onChangeFn={handlePackageSelection}
                    checked={packageSize === "M"}
                />
                <p className='small-subtitle'>Hasta una caja de zapatos.</p>
            </li>
            <li>
                <InputsRadio
                    id="L"
                    name="packageSize"
                    value="L"
                    labelTxt="Tamaño L"
                    onChangeFn={handlePackageSelection}
                    checked={packageSize === "L"}
                />
                <p className='small-subtitle'>Hasta una televisión.</p>
            </li>
            <li>
                <InputsRadio
                    id="XL"
                    name="packageSize"
                    value="XL"
                    labelTxt="Tamaño XL"
                    onChangeFn={handlePackageSelection}
                    checked={packageSize === "XL"}
                />
                <p className='small-subtitle'>Hasta una televisión.</p>
            </li>
        </motion.ul>
    )
}
