import React, { useEffect, useState } from 'react';
import info from '../../assets/icons/info.svg';

export const InputsSimple = ({ value, id, type, placeholder, labelTxt, onChangeFn, resultValidation, errorTxt, disabled = false }) => {
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        if (value) {
            setIsFocused(true)
        } else return
    }, [])

    const blurBehaviour = () => {
        if (!value) {
            setIsFocused(false)
        } else {
            return
        }
    }

    return (
        <div className='inputSimple-main-container'>
            <div className='input-wrapper'>
                <label htmlFor={id} className={`form-label ${isFocused ? "moveUp" : "moveDown"}`}>{labelTxt}</label>
                <input
                    type={type}
                    className={`form-control padding-lg ${resultValidation}`}
                    id={id}
                    placeholder={placeholder}
                    onChange={onChangeFn}
                    value={value}
                    onBlur={() => blurBehaviour()}
                    onFocus={() => setIsFocused(true)}
                    disabled={disabled}
                />
            </div>
            <span className="invalid-msg">{errorTxt}</span>
        </div>
    )
}

export const InputsWithTooltip = ({ value, id, type, placeholder, labelTxt, onChangeFn, children, min, max, disabled, resultValidation }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    function blurBehaviour() {
        if (!value) {
            setIsFocused(false)
        } else {
            return
        }
    }

    return (
        <div className='input-wrapper'>
            <label htmlFor={id} className={`form-label ${isFocused ? "moveUp" : ""}`}>{labelTxt}</label>
            <div className="icon-parent">
                {(type === "number") ?
                    <input type="number" className={`form-control ${resultValidation}`} id={id} placeholder={placeholder} onChange={onChangeFn} onBlur={() => blurBehaviour()} onFocus={() => setIsFocused(true)} value={value} min={min} max={max} step="0.01" disabled={disabled} />
                    :
                    <input type={type} className={`form-control ${resultValidation}`} id={id} placeholder={placeholder} onChange={onChangeFn} onBlur={() => blurBehaviour()} onFocus={() => setIsFocused(true)} value={value} disabled={disabled} />
                }

                {children &&
                    <div className="tooltip-parent">
                        <img src={info} alt="information" className="icon-child" onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} />

                        <div className={`tooltip-child ${showTooltip && "display"}`}>
                            {children}
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export const InputsSelect = ({ id, labelTxt, onChangeFn, arrayList, previouslySelected, resultValidation }) => {
    return (
        <div className="select-wrapper">
            <label htmlFor={id} className="form-label label-bold">{labelTxt}</label>

            <select
                className={`form-select ${resultValidation}`}
                aria-label={labelTxt}
                id={id}
                onChange={onChangeFn}
            >
                {previouslySelected ?
                    <option className="form-select-option" disabled>Seleccionar...</option>
                    :
                    <option className="form-select-option" selected disabled>Seleccionar...</option>
                }
                {arrayList.map((item, index) =>
                    <option key={`${index}`} className="form-select-option" value={item.value} selected={item.value === previouslySelected ? true : false} >{item.name}</option>
                )}
            </select>
        </div>
    )
}

export const InputsRadio = ({ id, name, value, labelTxt, onChangeFn, checked, disabled }) => {
    return (
        <div className="form-check mb-1 mb-xl-3">
            <input className="form-check-input" type="radio" name={name} id={id} value={value} checked={checked} onChange={onChangeFn} disabled={disabled} />
            <label className="form-check-label" htmlFor={id}>
                {labelTxt}
            </label>
        </div>
    )
}

export const InputsRadioAsButton = ({ id, name, value, labelTxt, onChangeFn, checked, disabled, extraClassName }) => {
    return (
        <div className={`radioBtn-wrapper ${extraClassName}`}>
            <input type="radio" name={name} id={id} value={value} checked={checked} onChange={onChangeFn} disabled={disabled} />
            <label htmlFor={id}>
                {labelTxt}
            </label>
        </div>
    )
}


export const InputsDuration = ({ ecommerce, value1, value2, id1, id2, units, labelTxt, onChangeFn, onBlurFn, min1, min2, max1, max2, resultValidation }) => {
    return (
        <div className={`from-to-wrapper ${ecommerce}`}>
            <h4 className="form-duration-label label-bold">{labelTxt}</h4>
            <div className="mt-2">
                <input type="number" className={`form-control ${resultValidation}`} id={id1} onChange={onChangeFn} value={value1} min={min1} max={max1} />
                <small>a</small>
                <input type="number" className={`form-control ${resultValidation}`} id={id2} onChange={onChangeFn} value={value2} min={min2} max={max2} onBlur={onBlurFn} />
                <small>{units}</small>
            </div>
        </div>
    )
}

export const InputsSwitch = ({ id, value, checked, onChangeFn, labelTxt }) => {
    return (
        <div className="form-check form-switch">
            <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id={id}
                value={value}
                onChange={onChangeFn}
                checked={checked} />
            <label className="form-check-label label-bold" htmlFor={id}>{labelTxt}</label>
        </div>
    )
}

export const InputsCheckbox = ({ id, value, onChangeFn, labelTxt, checked, disabled }) => {
    return (
        <div className="form-check">
            <input
                className="form-check-input"
                type="checkbox"
                id={id}
                value={value}
                onChange={onChangeFn}
                checked={checked}
                disabled={disabled} />
            <label className="form-check-label" htmlFor={id}>{labelTxt}</label>
        </div>
    )
}

export const InputsCheckboxAsButton = ({ id, value, onChangeFn, labelTxt, checked, disabled, extraClassName }) => {
    return (
        <div className={`radioBtn-wrapper ${extraClassName}`}>
            <input
                type="checkbox"
                id={id}
                value={value}
                checked={checked}
                onChange={onChangeFn}
                disabled={disabled}
            />
            <label htmlFor={id}>
                {labelTxt}
            </label>
        </div>
    )
}

export const InputsTextarea = ({ value, id, placeholder, labelTxt, onChangeFn, disabled = false, rows, cols, maxLength, resultValidation, errorTxt }) => {
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        if (value) {
            setIsFocused(true)
        } else return
    }, [])
    
    const blurBehaviour = () => {
        if (!value) {
            setIsFocused(false)
        } else {
            return
        }
    }
    return (
        <div className='inputSimple-main-container'>
            <div className='input-wrapper'>
                <label htmlFor={id} className={`form-label forTextArea ${isFocused ? "moveUp" : "moveDown"}`}>{labelTxt}</label>
                <textarea
                    className={`form-control padding-lg ${resultValidation}`}
                    id={id}
                    placeholder={placeholder}
                    onChange={onChangeFn}
                    value={value}
                    onBlur={() => blurBehaviour()}
                    onFocus={() => setIsFocused(true)}
                    disabled={disabled}
                    rows={rows}
                    cols={cols}
                    maxLength={maxLength}
                />
            </div>
            <span className="invalid-msg">{errorTxt}</span>
        </div>
    )
}
