import React from 'react'
import { InputsRadio } from '../../units/Inputs';
import { childVariants } from '../../../services/shared/animation.service';
import { motion } from 'framer-motion';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const OrdersDisplayInputs = ({ setAllOrdersSelected, allOrdersSelected, customerName }) => {

    const handleRadioChange = (e) => {
        setAllOrdersSelected(e.target.value)
    }

    return (
        <motion.div className='mt1' variants={childVariants}>
            <div className='radio-setting'>
                <InputsRadio
                    id="allOrders"
                    name="allOrdersSelected"
                    value="allOrders"
                    labelTxt="Todas las órdenes."
                    onChangeFn={handleRadioChange}
                    checked={allOrdersSelected === "allOrders"}
                />
                <p className='small-subtitle'>Sin importar el método de envío.</p>
            </div>
            <div className='radio-setting'>
                <InputsRadio
                    id="onlyCustomerMethod"
                    name="allOrdersSelected"
                    value="onlyCustomerMethod"
                    labelTxt={`Órdenes de ${customerName}.`}
                    onChangeFn={handleRadioChange}
                    checked={allOrdersSelected === "onlyCustomerMethod"}
                />
                <p className='small-subtitle'>Aquellas que se hayan realizado con este método de entrega.</p>
            </div>
        </motion.div>
    )
}


export const ConfigurationsSkeleton = ({ auth }) => {
    return (
        <>
            {auth?.user?.insidePanel &&
                <Skeleton count={1} height={100} style={{ marginBottom: "20px" }} />
            }
            <Skeleton count={1} height={60} style={{ marginBottom: "20px" }} />
            <Skeleton count={1} height={60} style={{ marginBottom: "2px" }} />
            <Skeleton count={1} height={60} style={{ marginBottom: "2px" }} />
            <Skeleton count={1} height={60} style={{ marginBottom: "2px" }} />
            <Skeleton count={1} height={60} style={{ marginBottom: "2px" }} />
        </>
    )
}

