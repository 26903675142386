import React from 'react'
import { childVariants } from '../../../services/shared/animation.service';
import { motion } from 'framer-motion';
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

export const DeliveryInfo = ({ accordionItems, setAccordionItems, availableInfo, requestedInfo }) => {

    const formatDate = (dateInfo) => {
        let turnToDate = new Date(dateInfo)
        let day = turnToDate.getDate() < 10 ? `0${turnToDate.getDate()}` : turnToDate.getDate();
        let month = turnToDate.getMonth() < 10 ? `0${turnToDate.getMonth()}` : turnToDate.getMonth();
        let hours = turnToDate.getHours() < 10 ? `0${turnToDate.getHours()}` : turnToDate.getHours();
        let minutes = turnToDate.getMinutes() < 10 ? `0${turnToDate.getMinutes()}` : turnToDate.getMinutes();

        return `${day}/${month}/${turnToDate.getFullYear()}, ${hours}:${minutes}`
    }

    return (
        <div id="deliveryInfo" className='mb-2 mb-lg-4'>
            <div className="accordionHeader">
                <div>
                    <h2 className="modal-subtitle">Datos del envío</h2>
                </div>
                <button type='button' className='accordion-btn' onClick={() => setAccordionItems({ third: !accordionItems.third, second: false, first: false })}>{accordionItems.third ? <FiChevronUp /> : <FiChevronDown />}</button>
            </div>
            {accordionItems.third &&
                <motion.div className='mt-1' variants={childVariants}>
                    <ul className='delivery-list'>
                        <li className='delivery-item'>
                            Estado: <span className='content highlight'>{availableInfo?.status}</span>
                        </li>
                        <li className='delivery-item'>
                            Fecha y hora de pedido: <span className='content gray'>{formatDate(availableInfo?.creationDate)}</span>
                        </li>
                        <li className='delivery-item'>
                            Modo: <span className='content'>{requestedInfo?.isAutomaticCreation ? "Automático" : "Manual"}</span>
                        </li>
                        <li className='delivery-item'>
                            Costo del envío de Cabify: <span className='content'>{availableInfo?.costValue!==null ? `$${availableInfo?.costValue}` : "No calculado"}</span>
                        </li>
                    </ul>
                </motion.div>
            }
        </div>
    )
}
