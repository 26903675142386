import React, { useEffect, useState } from 'react'
import { ButtonSmall } from '../../units/Button';
import { showError, showSuccess } from '../../../services/shared/alert.service';
import { useAuthStore } from '../../../store';
import { customerName, customerRouteName, trackedEvents } from '../../../data/InfoSource';
import { fadeInAndUp, fadeInAndUpNoDelay, growBtn } from '../../../services/shared/animation.service';
import { motion } from 'framer-motion';
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { amplitudeTrack, getPackage, savePackage } from '../../../services/onboarding.service';
import { Link, useHistory } from 'react-router-dom';
import { changeShopifyName } from '../../../services/shared/utils.service';
import { getOrdersSetting, setOrdersSetting } from '../../../services/panel.service';
import { ConfigurationsSkeleton, OrdersDisplayInputs } from './OrdersDisplayInputs';
import { PackagesInputs } from './PackagesInputs';

export const ConfigurationsForm = () => {
    let history = useHistory();
    const ecommerce = useAuthStore((state) => state.authData.user.ecommerce);
    const auth = useAuthStore((state) => state.authData);
    const [isLoading, setIsLoading] = useState(true);
    const [allOrdersSelected, setAllOrdersSelected] = useState("allOrders");
    const [packageSize, setPackageSize] = useState("");
    const [accordionItems, setAccordionItems] = useState({
        first: false,
        second: true
    })

    useEffect(() => {
        updatePackageSelection();
        if (auth?.user?.insidePanel) {
            updateSettings();
        }
    }, [])

    const updatePackageSelection = async () => {
        setIsLoading(true)
        try {
            let result = await getPackage(auth.user.storeId);
            if (result.packageSize && result.success) {
                setPackageSize((result.packageSize).toUpperCase())
            } else {
                setPackageSize("S")
            }
            if (result.error) {
                showError(result.error);
            }
            setIsLoading(false)
        } catch (error) {
            showError(error)
            setPackageSize("S")
            setIsLoading(false)
        }
    }

    const updateSettings = async () => {
        setIsLoading(true)
        try {
            const recoverSettings = await getOrdersSetting(auth.user.storeId);
            if (recoverSettings && recoverSettings.success) {
                if (recoverSettings?.configuration?.catchAllOrders) {
                    setAllOrdersSelected("allOrders")
                } else {
                    setAllOrdersSelected("onlyCustomerMethod")
                }
            }
            setIsLoading(false)
        } catch (error) {
            showError(error)
            setIsLoading(false)
        }
    }

    const setSettings = async (e) => {
        e.preventDefault();
        if (allOrdersSelected) {
            setIsLoading(true);
            let packageBody = {
                "packageSize": packageSize
            }
            let settingsBody = {
                "configuration": {
                    "catchAllOrders": allOrdersSelected === "allOrders" ? true : false,
                }
            }
            if (!auth.user.insidePanel) {
                amplitudeTrack("shopify-package_next_click", auth.user.storeId, trackedEvents["shopify-package_next_click"]);
            }
            try {
                const result = await savePackage(packageBody, auth.user.storeId);
                if (result && result.success) {
                    showSuccess(result.message);
                    if (!auth?.user?.insidePanel) {
                        history.push(`/${customerRouteName}/${changeShopifyName(ecommerce)}/finish`)
                    }
                }
                if (result.error) {
                    showError(result.error);
                }
                setIsLoading(false);
            } catch (error) {
                showError(error);
                setIsLoading(false);
            }
            if (auth?.user?.insidePanel) {
                try {
                    const result = await setOrdersSetting(settingsBody, auth.user.storeId);
                    if (result && result.success) {
                        showSuccess(result.message);
                    }
                    if (result.error) {
                        showError(result.error);
                    }
                    setIsLoading(false);
                } catch (error) {
                    showError(error);
                    setIsLoading(false);
                }
            }
        }
    }

    return (
        <form onSubmit={setSettings} id="config-p">
            {isLoading ?
                <ConfigurationsSkeleton auth={auth} />
                :
                <>
                    <motion.div className="configurations-form" variants={auth.user.insidePanel ? fadeInAndUpNoDelay : fadeInAndUp} initial="hidden" animate="visible">
                        {(auth?.user?.insidePanel) &&
                            <div id="settings" className='mb-2 mb-lg-4'>
                                <div className="accordionHeader">
                                    <div>
                                        <h1 className="main-title">Configuraciones</h1>
                                        <h2 className="main-subtitle">Selecciona cuáles son las órdenes por defecto que quieres ver en tu panel.</h2>
                                    </div>
                                    <button type='button' className='accordion-btn' onClick={() => setAccordionItems({ first: !accordionItems.first, second: false })}>{accordionItems.first ? <FiChevronUp /> : <FiChevronDown />}</button>
                                </div>
                                {accordionItems.first &&
                                    <OrdersDisplayInputs
                                        setAllOrdersSelected={setAllOrdersSelected}
                                        allOrdersSelected={allOrdersSelected}
                                        customerName={customerName}
                                    />
                                }
                            </div>
                        }
                        <div id="package">
                            <div className="accordionHeader">
                                <div>
                                    <h2 className="main-title">Tamaños de Producto</h2>
                                    <h3 className="main-subtitle">Selecciona el tamaño por defecto de tus paquetes.</h3>
                                </div>
                                {auth?.user?.insidePanel &&
                                    <button type='button' className='accordion-btn' onClick={() => setAccordionItems({ first: false, second: !accordionItems.second })}>{accordionItems.second ? <FiChevronUp /> : <FiChevronDown />}</button>
                                }
                            </div>
                            {accordionItems.second &&
                                <PackagesInputs
                                    setPackageSize={setPackageSize}
                                    packageSize={packageSize}
                                />
                            }
                        </div>
                        <div className={`bottomPage ${!auth?.user?.insidePanel ? "doubleBtn" : ""}`}>
                            {!auth?.user?.insidePanel &&
                                <motion.div variants={growBtn} initial="hidden" animate="visible">
                                    <Link to={`/${customerRouteName}/${changeShopifyName(ecommerce)}/rates`} className="btn btn-outline-secondary">Atrás</Link>
                                </motion.div>
                            }

                            <motion.div variants={growBtn} initial="hidden" animate="visible">
                                <ButtonSmall
                                    ecommerce={ecommerce}
                                    type="submit"
                                    btnTxt="Guardar"
                                    showSpinner={isLoading}
                                    isDisabled={(allOrdersSelected && packageSize) ? false : true}
                                />
                            </motion.div>
                        </div>
                    </motion.div>
                </>
            }
        </form>
    )
}
