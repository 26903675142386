import React from 'react'
import { childVariants } from '../../../services/shared/animation.service';
import { motion } from 'framer-motion';
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { InputsSelect } from '../../units/Inputs';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

export const Abstract = ({ accordionItems, setAccordionItems, requestedInfo, handleSelectChange }) => {
    const packageMeasures = [
        {
            name: "20x20x10 (S)",
            value: "S",
        },
        {
            name: "30x30x15 (M)",
            value: "M",
        },
        {
            name: "40x40x20 (L)",
            value: "L",
        },
        {
            name: "80x90x50 (XL)",
            value: "XL",
        },
    ]

    const calculateProductsSum = (objArr) => {
        return objArr.reduce((prev, curr) => { return prev + curr.productPrice }, 0)
    }

    return (
        <div id="abstract" className='mb-1 mb-lg-2'>
            <div className="accordionHeader">
                <div>
                    <h2 className="modal-subtitle">Resumen</h2>
                </div>
                <button type='button' className='accordion-btn' onClick={() => setAccordionItems({ first: !accordionItems.first, second: false, third: false })}>{accordionItems.first ? <FiChevronUp /> : <FiChevronDown />}</button>
            </div>
            {accordionItems.first &&
                <motion.div className='mt-1' variants={childVariants}>
                    <ul className='product-list'>
                        <Carousel
                            width="100%"
                            autoPlay={false}
                            infiniteLoop={false}
                            showThumbs={false}
                            showStatus={false}
                            showIndicators={false}
                            centerMode={true}
                            centerSlidePercentage={45}
                        >
                            {requestedInfo.products.map((product, index) => (
                                <div className='product-item' key={`product ${index + 1}`}>
                                    <h3 className='product-name modal-subtitle'>{product.productName}</h3>
                                    <p className='product-price'><b>Precio:</b> ${product.productPrice}</p>
                                </div>
                            ))}
                        </Carousel>
                    </ul>

                    <div className='price-wrapper'>
                        <ul className='price-list'>
                            <li className='price-item'>
                                <p>Productos:</p>
                                <p>${calculateProductsSum(requestedInfo.products)}</p>
                            </li>
                            <li className='price-item'>
                                <p>Costo de envío:</p>
                                <p>${requestedInfo.shippingCostMerchant}</p>
                            </li>
                            <li className='price-item total'>
                                <p>Total:</p>
                                <p>${calculateProductsSum(requestedInfo.products) + requestedInfo.shippingCostMerchant}</p>
                            </li>
                            <li className='price-item package-size'>
                                Tamaño del paquete:
                                <InputsSelect
                                    id="packageSize"
                                    labelTxt=""
                                    onChangeFn={handleSelectChange}
                                    arrayList={packageMeasures}
                                    previouslySelected={requestedInfo.packageSize}
                                />
                            </li>
                        </ul>
                    </div>
                </motion.div>
            }
        </div>
    )
}
