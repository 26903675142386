import React from 'react'
import { childVariants } from '../../../services/shared/animation.service';
import { motion } from 'framer-motion';
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { InputsSimple } from '../../units/Inputs';

export const ClientInfoInputs = ({ accordionItems, setAccordionItems, body, handleChange, formErrors }) => {
    return (
        <div id="clientInfo" className='mb-2 mb-lg-4'>
            <div className="accordionHeader">
                <div>
                    <h2 className="modal-subtitle">Datos del cliente</h2>
                </div>
                <button type='button' className='accordion-btn' onClick={() => setAccordionItems({ second: !accordionItems.second, first: false, third: false })}>{accordionItems.second ? <FiChevronUp /> : <FiChevronDown />}</button>
            </div>
            {accordionItems.second &&
                <motion.div className='clientInfo-inputs-wrapper mt-2' variants={childVariants}>
                    <div className="inputs-grid-wrapper">
                        <InputsSimple
                            value={body.fullName}
                            id="fullName"
                            type="text"
                            placeholder=""
                            labelTxt="Nombre y apellido*"
                            onChangeFn={handleChange}
                            resultValidation={formErrors?.fullName ? "is-invalid" : ""}
                            errorTxt={false}
                        />

                        <InputsSimple
                            value={body.phoneNumber}
                            id="phoneNumber"
                            type="text"
                            placeholder=""
                            labelTxt="Teléfono*"
                            onChangeFn={handleChange}
                            resultValidation={formErrors?.phoneNumber ? "is-invalid" : ""}
                            errorTxt={false}
                        />

                        <InputsSimple
                            value={body.notes}
                            id="notes"
                            type="text"
                            placeholder=""
                            labelTxt="Observaciones"
                            onChangeFn={handleChange}
                            resultValidation={formErrors?.notes ? "is-invalid" : ""}
                            errorTxt={false}
                        />
                    </div>
                    <div className="inputs-grid-wrapper">
                        <InputsSimple
                            value={body.address}
                            id="address"
                            type="text"
                            placeholder=""
                            labelTxt="Dirección*"
                            onChangeFn={handleChange}
                            resultValidation={formErrors?.address ? "is-invalid" : ""}
                            errorTxt={false}
                            disabled={false}
                        />

                        <InputsSimple
                            value={body.email}
                            id="email"
                            type="text"
                            placeholder="Ej: email@example.com"
                            labelTxt="Email*"
                            onChangeFn={handleChange}
                            resultValidation={formErrors?.email ? "is-invalid" : ""}
                            errorTxt={formErrors.email}
                        />
                    </div>
                </motion.div>
            }
        </div>
    )
}
