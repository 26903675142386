import purplePin from '../../../../assets/icons/customMarker.svg'
import L from 'leaflet'

export const personalizedIcon = L.icon({
    iconUrl: purplePin,
    iconRetinaUrl: purplePin,
    iconAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [30, 40],
    className: "leaflet-personalized-icon"
});