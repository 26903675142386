import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import queryString from 'query-string';
import { useAuthStore } from "../../store";
import { IntroColumn } from "../../components/onboarding/IntroColumn";
import { LoginForm } from "../../components/onboarding/authentication/LoginForm";
import { capitalizeFirstLetter, setEcommerceURL } from "../../services/shared/utils.service";
import { motion } from 'framer-motion';
import { fadeInAndUp } from "../../services/shared/animation.service";
import { urlInfo, customerName, trackedEvents } from '../../data/InfoSource';
import { showError } from "../../services/shared/alert.service";
import { amplitudeTrack } from "../../services/onboarding.service";

export const Credentials = () => {
  const location = useLocation();
  const [storeName, setStoreName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const addAuth = useAuthStore((state) => state.addAuthData);
  const user = useAuthStore((state) => state.authData.user);
  const [ecommerce, setEcommerce] = useState(setEcommerceURL(location));

  const { shop = "" } = queryString.parse(location.search);

  useEffect(() => {
    flowCorrector(user?.insidePanel);
    setUserInitialState();
    amplitudeTrack("shopify-credentials_land", shop, trackedEvents["shopify-credentials_land"]);
  }, []);

  const flowCorrector = (isPanelFlow) => {
    if (isPanelFlow) {
      addAuth({
        user: {
          ...user,
          insidePanel: false
        },
      });
    }
    document.title = `${customerName} - ${capitalizeFirstLetter(ecommerce)}`
  }

  const setUserInitialState = async () => {
    if (!storeName) {
      setIsLoading(true);
      if (ecommerce === "shopify" && shop) {
        setStoreName(extractStoreName(shop));
        addAuth({
          user: {
            storeId: shop,
            ecommerce: ecommerce,
          },
        });
      }
      setIsLoading(false);
    } else {
      showError();
    }
  }

  const extractStoreName = (fullShopName) => {
    let parts = fullShopName.split(".")
    return parts[0]
  }

  return (
    <div className="container-fluid" id="welcome">
      <div className="row">
        <IntroColumn ecommerce={ecommerce} storeName={storeName} isLoading={isLoading} shop={shop} />
        <div className="col col-8 form-col">
          <motion.div className="form-wrapper" variants={fadeInAndUp} initial="hidden" animate="visible">
            <h2 className="main-title">
              {ecommerce === "vtex" ? `Crear cuenta - ${customerName}` : `Ingresa tus credenciales de ${customerName}`}
            </h2>
            <p className="main-subtitle">
              Conecta tu cuenta de {customerName} con {capitalizeFirstLetter(ecommerce)}. ¿No conoces tus credenciales? <a href={urlInfo[ecommerce].contactUs} target="_blank" rel="noreferrer" className="btn-link fw-bold">Contáctanos.</a>
            </p>
            <LoginForm ecommerce={ecommerce} />
          </motion.div>
        </div>
      </div>
    </div>
  );
};
