import React from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonSmall, ButtonUpdate } from '../../units/Button';
import { InputsSwitch } from '../../units/Inputs';

export const LocationsConfigGeneralOption = ({ ...generalOptionsProps }) => {
    let history = useHistory();
    return (
        <div className="ps-4 mt-4 mb-3 d-flex justify-content-between align-items-center">
            <InputsSwitch
                id="all"
                value={generalOptionsProps.activationSwitch}
                checked={generalOptionsProps.activationSwitch}
                onChangeFn={generalOptionsProps.handleActiveAll}
                labelTxt={generalOptionsProps.activationSwitch ? "Desactivar todas las tiendas" : "Activar todas las tiendas"}
            />
            <div className="d-flex justify-content-end align-items-center">
                {generalOptionsProps.ecommerce === "vtex"
                    &&
                    <ButtonSmall
                        ecommerce={generalOptionsProps.ecommerce}
                        type="button"
                        btnTxt="Crear muelle"
                        showSpinner={false}
                        isDisabled={false}
                        onClickFn={() => history.push(`/${generalOptionsProps.customerRouteName}/vtex${generalOptionsProps.user.insidePanel ? '/panel' : ""}/create-center`)}
                        extraClass="btn-outline-primary"
                    />
                }
                {generalOptionsProps.user?.insidePanel &&
                    <ButtonUpdate isLoading={generalOptionsProps.isLoading} updateFunction={generalOptionsProps.updateLocations} />
                }
            </div>
        </div>
    )
}
