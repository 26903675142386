import src from "../../assets/labelsImage.png";
const LabelsImage = () => {
  return (
    <img
      src={src}
      alt="print labels illustration"
      className="print-labels-img"
    />
  );
};

export default LabelsImage;
