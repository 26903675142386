import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from './AppRoutes';
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.scss";
import { init } from '@amplitude/analytics-browser';

const amplitudeApiKey = process.env.REACT_APP_AMPLITUDE_API_KEY;

export const Routes = () => {
  init(amplitudeApiKey);

  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
};
