import React from 'react';
import { LogoContainerShopify, LogoContainerTiendanube, LogoContainerVtex } from '../units/LogoContainer';
import introIllustration from '../../assets/introIllustration.png';
import { capitalizeFirstLetter } from '../../services/shared/utils.service';
import { motion } from 'framer-motion';
import { urlInfo, customerName, trackedEvents } from '../../data/InfoSource';
import { childVariants, fadeInAndRight } from '../../services/shared/animation.service';
import { amplitudeTrack } from '../../services/onboarding.service';
import { useAuthStore } from '../../store';

export const IntroColumn = ({ ecommerce, storeName = "", isLoading, shop = "" }) => {
  const storeId = useAuthStore((state) => state.authData?.user?.storeId) || shop;
  const trackInAmplitude = () => {
    amplitudeTrack("shopify-welcome_create_cabify_account_click", storeId, trackedEvents["shopify-welcome_create_cabify_account_click"]);
  }

  return (
    <motion.div className={`col col-4 welcome-grid ${ecommerce}`} variants={fadeInAndRight} initial="hidden" animate="visible">
      {ecommerce === "shopify" && <LogoContainerShopify />}
      {ecommerce === "tiendanube" && <LogoContainerTiendanube ecommerce={ecommerce} />}
      {ecommerce === "vtex" && <LogoContainerVtex ecommerce={ecommerce} />}
      <div className="welcome-text">
        <div>
          <motion.h1 variants={childVariants} className="intro-title">
            {!isLoading && !storeName ? `¡Hola!` : `¡Hola ${storeName}!`}
          </motion.h1>
          <motion.p variants={childVariants} className="intro-subtitle">
            Gracias por comenzar a usar la aplicación de <span className="fw-bold">{customerName}</span> para <span className="fw-bold">{capitalizeFirstLetter(ecommerce)}</span>. Para continuar con el proceso de configuración, es necesario que ya cuentes con una cuenta en <span className="fw-bold">{customerName}</span>.
          </motion.p>
          <motion.p variants={childVariants} className="intro-subtitle">
            ¿Aún no tienes cuenta?
          </motion.p>
          <motion.div variants={childVariants}>
            <button type='button' style={{ background: 'none', border: 'none', padding: 0, margin: 0 }} onClick={trackInAmplitude}>
              <a href={`${urlInfo[ecommerce].createAccount}`} target="_blank" rel="noreferrer" className={`btn btn-secondary`}>Crear aquí</a>
            </button>
          </motion.div>
        </div>
      </div>
      <div className="illustration-wrapper">
        <img src={introIllustration} alt="" />
      </div>
    </motion.div>
  )
}
