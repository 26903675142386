import { useEffect, useState } from "react";
import { LogoContainerShopify } from "../units/LogoContainer";
import Loader from "./Loader";
import CheckIcon from "./CheckIcon";
import LabelsImage from "./LabelsImage";
import { motion } from "framer-motion";
import queryString from "query-string";
import { useLocation } from "react-router";
import { printLabel } from "../../services/panel.service";

const LabelsConfirmation = () => {
  const location = useLocation();
  const [fetching, setFetching] = useState(true);
  const [failed, setFailed] = useState(false);

  const tryPrint = async (parsedIds, shop) => {
    try {
      await printLabel(parsedIds, shop, setFetching, false, true);
    } catch (err) {
      setFailed(true);
    }
  };

  useEffect(() => {
    const params = queryString.parse(location.search);

    const parsedIds = () => {
      let ids;
      if (params["ids[]"]) {
        ids = Array.isArray(params["ids[]"])
          ? [...params["ids[]"]]
          : [params["ids[]"]];
      }
      if (params["id"]) {
        ids = [params["id"]];
      }
      return ids;
    };

    params && parsedIds && tryPrint(parsedIds(), params.shop);
  }, [location.search]);

  return (
    <main id="labels-container">
      <motion.header
        initial={{ opacity: 0, y: -5 }}
        animate={{ opacity: 1, y: 0, transition: { duration: 0.3 } }}
      >
        <LogoContainerShopify />
      </motion.header>

      {fetching && !failed ? (
        <Loader />
      ) : (
        <motion.section initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <div className="messageWrapper">
            <motion.header
              initial={{ opacity: 0, scale: 0.7 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                type: "spring",
                damping: 15,
                stiffness: 1000,
                duration: 1,
              }}
            >
              {!failed ? (
                <>
                  <CheckIcon />
                  <h2>¡Tus etiquetas han sido descargadas!</h2>
                </>
              ) : (
                <h4>
                  No encontramos etiquetas para las órdenes seleccionadas.
                </h4>
              )}
            </motion.header>

            {!failed && <LabelsImage />}
          </div>
        </motion.section>
      )}
    </main>
  );
};

export default LabelsConfirmation;
