import React, { useEffect, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Marker } from "react-leaflet";
import { personalizedIcon } from "./PersonalizedIcon";
import { showError } from "../../../../services/shared/alert.service";

export const AddressSearchAndMap = ({
  setAddressToVerify,
  locationInitialCoordinates,
  setNewAddressDetails,
  originalInfo,
}) => {
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isClear, setIsClear] = useState(true);

  const geoDataFinder = (
    arrSource,
    googleCategory,
    propertyNeeded = "long_name"
  ) => {
    let result = arrSource.find((obj) => obj.types[0] === googleCategory);
    if (
      result?.[propertyNeeded] === undefined &&
      (googleCategory === "street_number" || googleCategory === "route")
    ) {
      setIsClear(false);
      showError("Por favor reinténtalo. Recuerda escribir calle y número");
      setIsClear(true);
    } else {
      return result?.[propertyNeeded] || "";
    }
  };

  const handleAddressSelected = (place) => {
    setIsLoading(true);
    setAddressToVerify(place.formatted_address);

    let placeLatitude = place.geometry.location.lat();
    let placeLongitude = place.geometry.location.lng();

    setSelected([placeLatitude, placeLongitude]);
    setNewAddressDetails({
      ...originalInfo,
      address: `${geoDataFinder(
        place.address_components,
        "street_number"
      )} ${geoDataFinder(place.address_components, "route")}`,
      country: geoDataFinder(place.address_components, "country"),
      zip: geoDataFinder(place.address_components, "postal_code"),
      city: "",
      province: "",
      latitude: placeLatitude,
      longitude: placeLongitude,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    setSelected(locationInitialCoordinates);
    setIsLoading(false);
  }, [locationInitialCoordinates]);

  return (
    <>
      <div className="places-container">
        {isClear && (
          <Autocomplete
            apiKey={process.env.REACT_APP_PLACES_API_KEY}
            options={{
              types: ["address"],
            }}
            className="form-control"
            placeholder="Ingresa una dirección*"
            onPlaceSelected={handleAddressSelected}
          />
        )}
      </div>
      <div className="map-container">
        {!isLoading && (
          <MapContainer center={selected} zoom={15}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={selected} icon={personalizedIcon} />
          </MapContainer>
        )}
      </div>
    </>
  );
};
