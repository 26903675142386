import Swal from 'sweetalert2';
import errorIcon from '../../assets/icons/alertErrorIcon.svg';
import successIcon from '../../assets/icons/alertSuccessIcon.svg';

export const showError = async (message, timer) => {
    Swal.fire({
        html: `<div class="swal-container-error">
        <img src=${errorIcon} class="swal-icon" alt="red alert" />
        <p class="swal-text mb-0">${ message ? message : 'Ocurrió un error. Reinténtalo.'}</p>
        </div>`,
        timer: timer ? timer : 2000,
        showConfirmButton: false,
        background: 'hsla(0, 0%, 77%, 0)',
        backdrop: false,
        position: 'top-end',
        width: 0
    });
}


export const showSuccess = async (message, timer) => {
    Swal.fire({
        html: `<div class="swal-container-success">
        <img src=${successIcon} class="swal-icon" alt="green check" />
        <p class="swal-text mb-0">${ message ? message : 'Se completó la tarea con éxito.'}</p>
        </div>`,
        timer: timer ? timer : 2000,
        showConfirmButton: false,
        background: 'hsla(0, 0%, 77%, 0)',
        backdrop: false,
        position: 'top-end',
        width: 0
    });
}