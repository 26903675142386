import React, { useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { amplitudeTrack, getRates, requestBodySetter, saveRates } from '../../../services/onboarding.service';
import { showError, showSuccess } from '../../../services/shared/alert.service';
import { useAuthStore, useonboardingStore } from '../../../store';
import { ButtonSmall } from '../../units/Button';
import { changeShopifyName, mapFee, validateInputs } from '../../../services/shared/utils.service';
import { customerRouteName, customerName, trackedEvents } from '../../../data/InfoSource';
import { fadeInAndUp, fadeInAndUpNoDelay, growBtn } from '../../../services/shared/animation.service';
import { motion } from 'framer-motion';
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { setOrdersSetting } from '../../../services/panel.service';
import { RatesFormInputs, RatesSkeleton } from './RatesFormInputs';
import { RatesFormPromotionalInputs } from './RatesFormPromotionalInputs';
import { RatesFormTypeInputs } from './RatesFormTypeInputs';

export const RatesForm = ({ ecommerce }) => {
    let history = useHistory();
    const addData = useonboardingStore((state) => state.addData);
    const auth = useAuthStore((state) => state.authData);
    const store = useonboardingStore((state) => state.onboardingData.store);
    const [isLoading, setIsLoading] = useState(true);
    const [formErrors, setFormErrors] = useState({});
    const [typeOfFee, setTypeOfFee] = useState("");
    const [promoFreeShipping, setPromoFreeShipping] = useState(store?.currentlocations?.fee?.promotionalFreeShipping?.active || false);
    const [promoDynamicFee, setPromoDynamicFee] = useState(store?.currentlocations?.fee?.promotionalDynamicFee?.active || false);
    const [body, setBody] = useState({
        fee: "",
        promoFreeShipAmount: store?.currentlocations?.fee?.promotionalFreeShipping?.amount || 0,
        promoDynFeeAmount: store?.currentlocations?.fee?.promotionalDynamicFee?.amount || 0
    })
    const [serviceType, setServiceType] = useState("express");
    const [radioValue, setRadioValue] = useState('+');
    const radios = [
        { name: 'Descuento', value: '-' },
        { name: 'Recargo', value: '+' },
    ];
    const [promoComplete, setPromoComplete] = useState();
    const nextShopifyRouteParam = auth?.user?.insidePanel ? "panel/rates" : "package";
    const [accordionItems, setAccordionItems] = useState({
        first: true,
        second: false
    })

    useEffect(() => {
        updateRates();
    }, []);

    const updateRates = async () => {
        try {
            setIsLoading(true)
            let result = {};
            result = await getRates(auth.user.storeId);
            if (result?.data?.fee) {
                if (!result?.data?.fee?.type) {
                    setTypeOfFee("free");
                    setBody({ ...body, fee: 0 });
                } else {
                    setTypeOfFee(result?.data?.fee?.type);
                    if (result.data.fee.type === "dynamic" || result.data.fee.type === "free") {
                        setBody({ ...body, fee: 0 });
                    }
                    if (result?.data?.fee?.type === "fixed" && result?.data?.fee?.amount && result?.data?.fee?.amount > 0) {
                        setBody({ ...body, fee: result.data.fee.amount });
                    }
                }
                if (result?.data?.fee?.promotionalDynamicFee?.active) {
                    const values = mapFee(result.data.fee.promotionalDynamicFee.amount)
                    setRadioValue(values.operator);
                    setBody({ ...body, promoDynFeeAmount: values.number });
                }
                checkPromoValidation();
            }
            if(result?.data?.serviceType){
                setServiceType(result?.data.serviceType);
            }
            if (result.data?.error) {
                showError(result.data.error);
            }
            setIsLoading(false)
        } catch (error) {
            showError(error)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        checkPromoValidation()
    }, [body, promoFreeShipping, promoDynamicFee])

    const handleCheckboxes = (e) => {
        if (e.target.id === "promoFreeShipping") {
            setPromoFreeShipping(!promoFreeShipping)
            if (!promoFreeShipping === false) {
                setBody({ ...body, promoFreeShipAmount: 0 });
            }
        }
        if (e.target.id === "promoDynamicFee") {
            setPromoDynamicFee(!promoDynamicFee)
            if (!promoDynamicFee === false) {
                setBody({ ...body, promoDynFeeAmount: 0 });
            }
        }
    }

    const checkPromoValidation = () => {
        if ((promoFreeShipping && body.promoFreeShipAmount === 0) || (promoDynamicFee && body.promoDynFeeAmount === 0)) {
            setPromoComplete(false)
        } else {
            setPromoComplete(true)
        }
    }

    const setDeliveryPromise = async (e) => {
        e.preventDefault();
        if (validateInputs(body, setFormErrors)) {
            setIsLoading(true);
            let composedBody = requestBodySetter(typeOfFee, body, promoFreeShipping, promoDynamicFee, radioValue, serviceType);
            if (!auth.user.insidePanel) {
                amplitudeTrack("shopify-cost_next_click", auth.user.storeId, trackedEvents["shopify-cost_next_click"], { tariff: typeOfFee, 'flat-tariff': body.fee });
            }
            try {
                await setOrdersSetting({ configuration: { catchAllOrders: false } }, auth.user.storeId)
                const result = await saveRates(composedBody, auth.user.storeId);
                if (result && result.success) {
                    addData({
                        store: {
                            ...store,
                            fee: composedBody.data
                        }
                    });
                    showSuccess(result.message);
                    history.push(`/${customerRouteName}/${changeShopifyName(ecommerce)}/${nextShopifyRouteParam}`)
                }
                if (result.error) {
                    showError(result.error);
                }
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                showError(error);
            }
        } else {
            return
        }
    }
    let basicInputsProps = { typeOfFee, body, setBody, formErrors, customerName, checkPromoValidation, setPromoFreeShipping, setPromoDynamicFee, store, setTypeOfFee };
    let serviceProps = { serviceType, setServiceType }
    let promotionalProps = { accordionItems, setAccordionItems, promoFreeShipping, handleCheckboxes, typeOfFee, body, formErrors, promoDynamicFee, radios, radioValue, ecommerce, setRadioValue, checkPromoValidation, setBody };
    return (
        <form onSubmit={setDeliveryPromise} id="rate-p">
            {isLoading ?
                <RatesSkeleton auth={auth} />
                :
                <>
                    <motion.div className="rates-form" variants={auth.user.insidePanel ? fadeInAndUpNoDelay : fadeInAndUp} initial="hidden" animate="visible">
                        <div className="accordionHeader">
                            <div>
                                <h2 className="main-title">Costos de envío</h2>
                                <h3 className="main-subtitle">Las configuraciones de tarifa, son generales para el medio de envío.</h3>
                            </div>
                            {auth?.user?.insidePanel &&
                                <button type='button' className='accordion-btn' onClick={() => setAccordionItems({ first: !accordionItems.first, second: false })}>{accordionItems.first ? <FiChevronUp /> : <FiChevronDown />}</button>
                            }
                        </div>
                        {accordionItems.first &&
                            <>
                                <RatesFormInputs {...basicInputsProps} />
                                <RatesFormTypeInputs {...serviceProps} />
                            </>
                        }
                        {(auth?.user?.insidePanel) &&
                            <RatesFormPromotionalInputs {...promotionalProps} />
                        }
                        <div className={`bottomPage ${!auth?.user?.insidePanel ? "doubleBtn" : ""}`}>
                            {!auth?.user?.insidePanel &&
                                <motion.div variants={growBtn} initial="hidden" animate="visible">
                                    <Link to={`/${customerRouteName}/${changeShopifyName(ecommerce)}/location-list`} className="btn btn-outline-secondary">Atrás</Link>
                                </motion.div>
                            }
                            <motion.div variants={growBtn} initial="hidden" animate="visible">
                                <ButtonSmall
                                    ecommerce={ecommerce}
                                    type="submit"
                                    btnTxt={(((typeOfFee === "free" || typeOfFee === "dynamic") && Number(body.fee) === 0) || (typeOfFee === "fixed" && Number(body.fee) > 0)) ? "Guardar" : "Completa todos los datos para continuar"}
                                    showSpinner={isLoading}
                                    isDisabled={
                                        ((typeOfFee === "free" || typeOfFee === "dynamic") && Number(body.fee) === 0 && promoComplete) || ((typeOfFee === "fixed" && Number(body.fee) > 0) && promoComplete) ? false : true
                                    }
                                />
                            </motion.div>
                        </div>
                    </motion.div>
                </>
            }
        </form>
    )
}