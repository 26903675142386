import React, { useEffect, useState } from 'react'
import { InputsRadioAsButton } from '../../../units/Inputs';

export const PreparationMinutes = ({ timeOptions, actualValue, isPersonalizedTime, setIsPersonalizedTime, setPreparationTime }) => {
    const [showPersonalizedTime, setShowPersonalizedTime] = useState(false);

    useEffect(() => {
        if(isPersonalizedTime){
            setShowPersonalizedTime(true)
        }
    }, [isPersonalizedTime])
    
    const handlePreparationChange = (e) => {
        if (e.target.id === "personalizedTime") {
            setIsPersonalizedTime(true)
        } else {
            setIsPersonalizedTime(false)
        }
        setPreparationTime(Number(e.target.value));
    }

    return (
        <div>
            <h2 className="main-subtitle">¿Cuánto tiempo demoras en preparar tus paquetes?</h2>
            <div className='radioAsBtns-wrapper'>
                {
                    timeOptions.map((option, index) => (
                        <InputsRadioAsButton
                            key={`time ${index}`}
                            id={`time ${index}`}
                            name="preparationTime"
                            value={option.value}
                            labelTxt={option.label}
                            onChangeFn={(e) => {setShowPersonalizedTime(false); handlePreparationChange(e)}}
                            checked={actualValue === option.value}
                            disabled={false}
                        />
                    ))
                }
                <InputsRadioAsButton
                    id="other"
                    name="preparationTime"
                    value={0}
                    labelTxt="Otro"
                    onChangeFn={(e) => {setShowPersonalizedTime(true); handlePreparationChange(e)}}
                    checked={isPersonalizedTime}
                    disabled={false}
                />
            </div>
            {showPersonalizedTime &&
                <div className='radioBtns-personalized'>
                    <p>Personalizado:</p>
                    <input
                        type="number"
                        className="form-control"
                        id="personalizedTime"
                        placeholder=""
                        onChange={handlePreparationChange}
                        value={String(actualValue).replace(/^0+/, '')}
                        min={0}
                        pattern="[0-9]"
                        step="1"
                        disabled={false}
                    />
                    <p>días</p>
                </div>
            }
        </div>
    )
}
