import { cancelOrder, createOrder, deleteOrder, getCostOfDelivery, getOrderTicket, printLabel, restoreSingleOrder, retryOrder } from "./panel.service";
import { showError, showSuccess } from "./shared/alert.service";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import infoFull from "../assets/icons/infoFull.svg";
import { HiTrash } from "react-icons/hi";
import purchaseEvidence from '../assets/icons/purchaseEvidence.svg';
import printLabelIcon from '../assets/icons/printLabel.svg';
import followUp from '../assets/icons/followUp.svg';
import { ButtonSmall } from "../components/units/Button";

// Prepare body and set actions for single and multiple orders
export const setOrdersAction = async (rowIds, storeId, tabSelected, setIsLoading, updateTable, action, resetOrdersSelections) => {
  setIsLoading(true);
  let ordersArr = {};
  if (typeof rowIds === "string") {
    ordersArr = {
      orderIds: [rowIds],
    }
  } else {
    ordersArr = {
      orderIds: [...rowIds],
    }
  }
  let result = {};
  try {
    if (action === "fulfill") {
      result = await createOrder(ordersArr, storeId)
    }
    if (action === "retry") {
      result = await retryOrder(ordersArr, storeId)
    }
    if (action === "delete") {
      result = await deleteOrder(ordersArr, storeId)
    }
    if (action === "cancel") {
      result = await cancelOrder(ordersArr, storeId)
    }
    if (action === "restore") {
      result = await restoreSingleOrder(ordersArr, storeId)
    }
    if (result && !result?.success) {
      showError(`${result?.message}`);
    }
    if (result && result.success) {
      showSuccess(`${result?.message}`);
      setTimeout(() => {
        updateTable(storeId, "all")
      }, 500);
    }
  } catch (error) {
    showError(error);
  }
  setIsLoading(false);
  resetOrdersSelections();
};


//massive ticket print
async function getInfoFromBase64(base64) {
  const meta = base64.split(',')[0];
  const rawBase64 = base64.split(',')[0].replace(/\s/g, '');
  const mime = /:([^;]+);/.exec(meta);
  const extension = /\/([^;]+);/.exec(meta);

  return {
    mime,
    extension,
    meta,
    rawBase64
  };
}

export async function convertBase64ToBlob(base64) {
  const info = await getInfoFromBase64(base64);
  const sliceSize = 512;
  const byteCharacters = window.atob(info.rawBase64);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    byteArrays.push(new Uint8Array(byteNumbers));
  }
  return new Blob(byteArrays, { type: 'application/pdf' });
}

export const printTickets = async (ordersToPrint, setIsLoading, storeId) => {
  let ordersArr = {};
  if (typeof ordersToPrint === "string") {
    ordersArr = {
      orderIds: [ordersToPrint],
    }
  } else {
    ordersArr = {
      orderIds: [...ordersToPrint],
    }
  }
  setIsLoading(true);
  try {
    const result = await getOrderTicket(ordersArr, storeId);
    if (result) {
      const deliberyLabel = await convertBase64ToBlob(result.base64PDF);
      const blobUrl = URL.createObjectURL(deliberyLabel);
      window.open(blobUrl, "_blank");
    }
    setIsLoading(false);
  } catch (error) {
    showError(error)
    setIsLoading(false);
  }
}

// --- pagination ---
const customTotal = (from, to, size) => (
  <span className="pagination-total-msg">
    Mostrar
    {/* Mostrando { to } de { size } órdenes */}
  </span>
);

export const options = {
  prePageText: "<",
  nextPageText: ">",
  disablePageTitle: true,
  showTotal: true,
  paginationTotalRenderer: customTotal,
  sizePerPageList: [
    {
      text: "10",
      value: 10,
    },
    {
      text: "20",
      value: 20,
    },
    {
      text: "40",
      value: 40,
    },
    {
      text: "50",
      value: 50,
    },
    {
      text: "100",
      value: 100,
    },
  ],
};

// --- formatters ---
const infoActionFormatter = (cell, row, rowIndex, {...infoExtraData}) => {
  return (
    <button type='button' className='info-btn'
      onClick={() => {
        infoExtraData.setMoreInfoModalProps({
          handleClose: () => infoExtraData.setShowMoreInfoModal(false),
          orderId: row.orderId,
          storeId: infoExtraData.auth?.user?.storeId,
          orderData: row
        })
        infoExtraData.setShowMoreInfoModal(true)
      }}
    >
      <img src={infoFull} alt="info" />
    </button>
  )
}

const actionsFormatter = (cell, row, rowIndex, {...followUpProps}) => {
  return (
    <div className="actions-icons">
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 250 }}
        overlay={<Tooltip >Imprimir etiqueta</Tooltip>}
      >
        <button
          type="button"
          className={`btn ${row.originalStatus !== "pending" ? "" : "disabled"}`}
          onClick={() => {
            printLabel(row.shopifyOrderId, followUpProps.auth?.user?.storeId, followUpProps.setLoadingBtnAction)
          }}
        >
          <img src={printLabelIcon} alt="print label" />
        </button>
      </OverlayTrigger>

      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 250 }}
        overlay={<Tooltip >Seguir envío</Tooltip>}
      >
        <a href={row.trackingUrl} target="_blank" rel="noreferrer" className={`btn ${(row.overallTab === "created" || row.overallTab === "delivered") ? "" : "disabled"}`}>
          <img src={followUp} alt="follow up" />
        </a>
      </OverlayTrigger>

      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 250 }}
        overlay={<Tooltip >Ver evidencia de entrega</Tooltip>}
      >
        <button
          type="button"
          className={`btn ${row.overallTab === "delivered" ? "" : "disabled"}`}
          onClick={() => {
            followUpProps.setEvidenceModalProps({
              handleClose: () => followUpProps.setShowEvidenceModal(false),
              orderId: row.orderId,
              storeId: followUpProps.auth?.user?.storeId,
            })
            followUpProps.setShowEvidenceModal(true)
          }}
        >
          <img src={purchaseEvidence} alt="purchase evidence" />
        </button>
      </OverlayTrigger>


      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 250 }}
        overlay={<Tooltip>Eliminar</Tooltip>}
      >
        <button
          type="button"
          className={`btn ${(row.originalStatus === "pending" || row.overallTab === "pending") ? "" : "disabled"}`}
          onClick={() => {
            followUpProps.setConfirmationModalProps({
              handleClose: () => followUpProps.setShowConfirmationModal(false),
              bodyText: "¿Deseas eliminar la orden?",
              mainTask: () => setOrdersAction(row.orderId, followUpProps.auth?.user?.storeId, followUpProps.tabSelected, followUpProps.setIsLoading, followUpProps.updateTable, "delete", followUpProps.resetOrdersSelections)
            })
            followUpProps.setShowConfirmationModal(true)
          }
          }
        >
          <HiTrash />
        </button>
      </OverlayTrigger>
    </div>
  );
};

const singleActionsFormatter = (cell, row, rowIndex, {...actionButtonsProps}) => {
  if (row.overallTab === "pending") {
    return (
      <button
        className="badge panel-format"
        onClick={() => actionButtonsProps.setOrdersAction(row.orderId, actionButtonsProps.auth?.user?.storeId, actionButtonsProps.tabSelected, actionButtonsProps.setIsLoading, actionButtonsProps.updateTable, "fulfill", actionButtonsProps.resetOrdersSelections)}
      >
        Solicitar repartidor
      </button>
    );
  }
  if (row.originalStatus === "ready" || row.originalStatus === "qualifiedforpickup") {
    return (
      <button
        className="badge panel-format"
        onClick={() => {
          actionButtonsProps.setConfirmationModalProps({
            handleClose: () => actionButtonsProps.setShowConfirmationModal(false),
            bodyText: "¿Estás seguro que deseas cancelar el envío en curso? Esta acción podría tener costos extra",
            mainTask: () => actionButtonsProps.setOrdersAction(row.orderId, actionButtonsProps.auth?.user?.storeId, actionButtonsProps.tabSelected, actionButtonsProps.setIsLoading, actionButtonsProps.updateTable, "cancel", actionButtonsProps.resetOrdersSelections)
          })
          actionButtonsProps.setShowConfirmationModal(true)
        }}
      >
        Cancelar
      </button>
    );
  }
  if (row.overallTab === "delivered") {
    return (
      <button
        className="badge panel-format"
        onClick={() => actionButtonsProps.setOrdersAction(row.orderId, actionButtonsProps.auth?.user?.storeId, actionButtonsProps.tabSelected, actionButtonsProps.setIsLoading, actionButtonsProps.updateTable, "retry", actionButtonsProps.resetOrdersSelections)}
      >
        Volver a solicitar
      </button>
    );
  }
  if (row.originalStatus === "error") {
    return (
      <button
        className="badge panel-format"
        onClick={() => actionButtonsProps.setOrdersAction(row.orderId, actionButtonsProps.auth?.user?.storeId, actionButtonsProps.tabSelected, actionButtonsProps.setIsLoading, actionButtonsProps.updateTable, "fulfill", actionButtonsProps.resetOrdersSelections)}
      >
        Reintentar
      </button>
    );
  }
  if (row.overallTab === "issue" && (row.originalStatus !== "error")) {
    return (
      <button
        className="badge panel-format"
        onClick={() => actionButtonsProps.setOrdersAction(row.orderId, actionButtonsProps.auth?.user?.storeId, actionButtonsProps.tabSelected, actionButtonsProps.setIsLoading, actionButtonsProps.updateTable, "restore", actionButtonsProps.resetOrdersSelections)}
      >
        Restaurar
      </button>
    );
  }
};

export const statusFormatter = (cell, row) => {
  if (row.overallTab === "pending") {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 250 }}
        overlay={<Tooltip >{row.message}</Tooltip>}
      >
        <span
          className="pending-status-badge"
          style={{ cursor: "default" }}
        >
          {row.status}
        </span>
      </OverlayTrigger>
    );
  }
  if (row.overallTab === "created") {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 250 }}
        overlay={<Tooltip >{row.message}</Tooltip>}
      >
        <span
          className="created-status-badge"
          style={{ cursor: "default" }}
        >
          {row.status}
        </span>
      </OverlayTrigger>
    );
  }
  if (row.overallTab === "delivered") {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 250 }}
        overlay={<Tooltip >{row.message}</Tooltip>}
      >
        <span
          className="delivered-status-badge"
          style={{ cursor: "default" }}
        >
          {row.status}
        </span>
      </OverlayTrigger>
    );
  }
  if (row.overallTab === "issue") {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 250 }}
        overlay={<Tooltip >{row.message}</Tooltip>}
      >
        <span
          className="issue-status-badge"
          style={{ cursor: "default" }}
        >
          {row.status}
        </span>
      </OverlayTrigger>
    );
  }
};

const costActionFormatter = (cell, row, rowIndex, {...deliveryCostProps}) => {
  if (cell !== null) {
    return (
      <span>${cell}</span>
    )
  } else {
    return (
      <ButtonSmall
        type="button"
        btnTxt="Calcular"
        showSpinner={false}
        isDisabled={false}
        onClickFn={() => deliveryCostProps.getDeliveryCost(row.orderId, deliveryCostProps.auth?.user?.storeId, deliveryCostProps.tabSelected, deliveryCostProps.setIsLoading, deliveryCostProps.updateTable)}
        extraClass="grid-fixed-size outlined"
      />
    )
  }
}

export const getDeliveryCost = async(rowId, storeId, tabSelected, setIsLoading, updateTable) => {
  setIsLoading(true);
  const body = {
    orderId: rowId
  }
  try {
    const result = await getCostOfDelivery(body, storeId)
    if (result && result.success) {
      showSuccess(result?.message);
      updateTable(storeId, tabSelected);
      setIsLoading(false);
    }
  } catch (error) {
    showError(error);
    setIsLoading(false);
  }
}

export const dateFormatter = (cell, row) => {
  const orderDate = new Date(row.date);
  return <span>{`${orderDate.getDate()}/${orderDate.getMonth() + 1}/${orderDate.getFullYear()}`}</span>
}

// --- date filter ---
export const handleDateChange = async (dates, setStartDate, setEndDate) => {
  const [start, end] = dates;
  setStartDate(start);
  setEndDate(end);
};

export const filterData = (data, startDate, endDate, setFilteredByDateList) => {
  if (startDate && endDate) {
    let filtered = data.filter((order) => {
      let date = new Date(order.creationDate);
      let afterEndDate = new Date(endDate.toString());
      afterEndDate.setDate(afterEndDate.getDate() + 1);
      return date >= new Date(startDate.toString()) && date < afterEndDate;
    });
    setFilteredByDateList(filtered);
  } else {
    showError("Por favor selecciona una fecha final para el filtro.")
    return
  }
};

// --- columns structure ---
export const columns = (infoExtraData, followUpProps, actionButtonsProps, deliveryCostProps) => {
  return (
    [
      {
        dataField: "information",
        text: "INFO",
        formatter: (cell, row, rowIndex) => infoActionFormatter(cell, row, rowIndex, infoExtraData),
        headerClasses: () => {
          return "grid-width-xs";
        },
        classes: () => {
          return "grid-width-xs";
        },
      },
      {
        dataField: "ecommerceNumber",
        text: "# ORDEN",
        headerClasses: () => {
          return "grid-width-sm-md";
        },
        classes: () => {
          return "grid-width-sm";
        },
      },
      {
        dataField: "date",
        text: "FECHA",
        formatter: dateFormatter,
        sort: true,
        classes: () => {
          return "grid-width-sm";
        },
        headerClasses: () => {
          return "grid-width-sm";
        },
      },
      {
        dataField: "origin",
        text: "ORIGEN",
        headerClasses: () => {
          return "grid-width-md";
        },
        classes: () => {
          return "grid-width-md";
        },
      },
      {
        dataField: "client",
        text: "CLIENTE",
        headerClasses: () => {
          return "grid-width-md";
        },
        classes: () => {
          return "grid-width-md";
        },
      },
      {
        dataField: "status",
        text: "STATUS CABIFY",
        formatter: statusFormatter,
        classes: () => {
          return "grid-width-md";
        },
        headerClasses: () => {
          return "grid-width-md";
        },
      },
      {
        dataField: "costValue",
        text: "COSTO ENVÍO",
        formatter: (cell, row, rowIndex) => costActionFormatter( cell, row, rowIndex, deliveryCostProps),
        classes: () => {
          return "grid-width-md";
        },
        headerClasses: () => {
          return "grid-width-md";
        },
      },
      {
        dataField: "singleActions",
        text: "ACCIONES DE ENVÍO",
        formatter: (cell, row, rowIndex) => singleActionsFormatter(cell, row, rowIndex, actionButtonsProps),
        classes: (cell, row, rowIndex, colIndex) => {
          return "grid-width-lg";
        },
        headerClasses: () => {
          return "grid-width-lg";
        },
      },
      {
        dataField: "actions",
        text: "SEGUIMIENTO",
        formatter: (cell, row, rowIndex) => actionsFormatter(cell, row, rowIndex, followUpProps),
        classes: () => {
          return "grid-width-md";
        },
        headerClasses: () => {
          return "grid-width-md";
        },
      },
    ]
  )
}

export const noSelection = {
  mode: "checkbox",
  hideSelectColumn: true
}

export const onRowSelect = (row, isSelected, ordersSelected, setOrdersSelected, ordersForCancel, setOrdersForCancel) => {
  if((row.originalStatus === "ready" || row.originalStatus === "qualifiedforpickup")){
    let order = Array.from(ordersForCancel);
    if (isSelected) {
      order.push(
        row.orderId,
      );
    } else {
      const index = order.indexOf(row.orderId)
      if (index > -1) {
        order.splice(index, 1);
      }
    }
    setOrdersForCancel(order);
  }

  let order = Array.from(ordersSelected);
  if (isSelected) {
    order.push(
      row.orderId,
    );
  } else {
    const index = order.indexOf(row.orderId)
    if (index > -1) {
      order.splice(index, 1);
    }
  }
  setOrdersSelected(order);

}

export const onSelectAll = (isSelected, rows, setOrdersSelected, setOrdersForCancel) => {
  let order = [];
  let cancelOrder = [];

  if (isSelected) {
    for (let i = 0; i < rows.length; i++) {
      if((rows[i].originalStatus === "ready" || rows[i].originalStatus === "qualifiedforpickup")){
        cancelOrder.push(
          rows[i].orderId,
        );
      }
      order.push(
        rows[i].orderId,
      );
    }
  }
  if (!isSelected) {
    order = [];
    cancelOrder = [];
  }
  setOrdersSelected(order);
  setOrdersForCancel(cancelOrder)
  return order;
}