import React from 'react';
import customerColor from "../../assets/customerLogo/customerWhite.png";
import customerCondensed from "../../assets/customerLogo/condensedWhite.png";
import tiendanubeLogo from "../../assets/ecommerceLogos/tiendanubeWhite.png";
import vtexLogo from "../../assets/ecommerceLogos/vtexWhite.png";
import { customerName } from '../../data/InfoSource';

export const LogoContainerShopify = ({ isCondensed }) => {
    return (
        <>
            {
                isCondensed ?
                    <div className="logo-container">
                        < img src={customerCondensed} alt={`${customerName} logo`
                        } />
                    </div >
                    :
                    <div className="logo-container">
                        <img src={customerColor} alt={`${customerName} logo`} />
                    </div>

            }
        </>
    )
}

export const LogoContainerTiendanube = ({ ecommerce }) => {
    return (
        <div className="logo-container">
            <img src={customerColor} alt={`${customerName} logo`} />
            <img src={tiendanubeLogo} alt="Tiendanube logo" className={`${ecommerce}`} />
        </div>
    )
}

export const LogoContainerVtex = ({ ecommerce }) => {
    return (
        <div className="logo-container">
            <img src={customerColor} alt={`${customerName} logo`} />
            <img src={vtexLogo} alt="Vtex logo" className={`${ecommerce}`} />
        </div>
    )
}
