import React from 'react';
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { childVariants } from '../../../services/shared/animation.service';
import { motion } from 'framer-motion';
import { InputsCheckbox, InputsWithTooltip } from '../../units/Inputs';

export const RatesFormPromotionalInputs = ({ ...promotionalProps }) => {

    const handleChange = (e) => {
        promotionalProps.setBody({ ...promotionalProps.body, [e.target.id]: Number(e.target.value) });
        promotionalProps.checkPromoValidation()
    };

    return (
        <div className='promotional-container mt-3 mt-xl-4 mt-xxl-5'>
            <div className="accordionHeader">
                <div>
                    <h2 className="main-title">Tarifas promocionales</h2>
                    <h3 className="main-subtitle">Defina las configuraciones promocionales.</h3>
                </div>
                <button type='button' className='accordion-btn' onClick={() => promotionalProps.setAccordionItems({ first: false, second: !promotionalProps.accordionItems.second })}>{promotionalProps.accordionItems.second ? <FiChevronUp /> : <FiChevronDown />}</button>
            </div>
            {promotionalProps.accordionItems.second &&
                <motion.div className='mt-2 mt-xxl-3' variants={childVariants}>
                    <InputsCheckbox
                        id="promoFreeShipping"
                        value={promotionalProps.promoFreeShipping}
                        checked={promotionalProps.promoFreeShipping === true}
                        onChangeFn={promotionalProps.handleCheckboxes}
                        labelTxt="Tarifa de envío gratis por monto del carrito."
                        disabled={promotionalProps.typeOfFee === "dynamic" || promotionalProps.typeOfFee === "free"}
                    />
                    {promotionalProps.promoFreeShipping &&
                        <div className='promo-phrase-shop'>
                            <span className="form-check-label">Quiero aplicar envío gratis a compras superiores a $</span>
                            <InputsWithTooltip
                                value={String(promotionalProps.body.promoFreeShipAmount).replace(/^0+/, '')}
                                id="promoFreeShipAmount"
                                type="number"
                                onChangeFn={handleChange}
                                min={1}
                                disabled={promotionalProps.typeOfFee === "dynamic" || promotionalProps.typeOfFee === "free"}
                            />
                            {promotionalProps.formErrors.promoFreeShipAmount && <span className="invalid-msg">{promotionalProps.formErrors.promoFreeShipAmount}</span>}
                        </div>
                    }
                    <InputsCheckbox
                        id="promoDynamicFee"
                        value={promotionalProps.promoDynamicFee}
                        onChangeFn={promotionalProps.handleCheckboxes}
                        labelTxt="Tarifa dinámica ajustada."
                        checked={promotionalProps.promoDynamicFee === true}
                        disabled={promotionalProps.typeOfFee === "fixed" || promotionalProps.typeOfFee === "free"}
                    />
                    {promotionalProps.promoDynamicFee &&
                        <>
                            <div className='promo-phrase-shop'>
                                <span className="form-check-label">Quiero aplicar</span>
                                <InputsWithTooltip
                                    value={String(promotionalProps.body.promoDynFeeAmount).replace(/^0+/, '')}
                                    id="promoDynFeeAmount"
                                    type="number"
                                    onChangeFn={promotionalProps.handleChange}
                                    disabled={promotionalProps.typeOfFee === "fixed" || promotionalProps.typeOfFee === "free"}
                                />
                                <span className="form-check-label">% de</span>
                                <div className={`radios-wrapper ${promotionalProps.ecommerce}`}>
                                    {promotionalProps.radios.map((radio, idx) => (
                                        <div key={idx}>
                                            <input
                                                id={`radio-${idx}`}
                                                type="radio"
                                                name="radio"
                                                value={radio.value}
                                                checked={radio.value === promotionalProps.radioValue}
                                                onChange={(e) => promotionalProps.setRadioValue(e.currentTarget.value)}
                                                className={`btn-check`}
                                                disabled={promotionalProps.typeOfFee === "fixed" || promotionalProps.typeOfFee === "free"}
                                            />
                                            <label className={`btn btn-outline-custom`} htmlFor={`radio-${idx}`}>{radio.name}</label>
                                        </div>
                                    ))}
                                </div>

                            </div>
                            {promotionalProps.formErrors.promoDynFeeAmount && <span className="invalid-msg ms-4">{promotionalProps.formErrors.promoDynFeeAmount}</span>}
                        </>
                    }
                </motion.div>
            }
        </div>
    )
}
