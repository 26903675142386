import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion';
import { fadeInAndUp, fadeInAndUpNoDelay } from '../../../../services/shared/animation.service';
import { changeShopifyName, deepCopy, noSpacedNameShortener, validateFullAddress } from '../../../../services/shared/utils.service';
import { useAuthStore, useonboardingStore } from '../../../../store';
import { PreparationMinutes } from './PreparationMinutes';
import { useHistory, useLocation } from 'react-router-dom';
import { showError, showSuccess } from '../../../../services/shared/alert.service';
import { InputsCheckbox } from '../../../units/Inputs';
import { AutomaticSetter } from './AutomaticSetter';
import { customerRouteName, trackedEvents } from '../../../../data/InfoSource';
import { amplitudeTrack, checkForEnablingButton, configSingleLocation, saveLocationsData, timeOptions } from '../../../../services/onboarding.service';
import { PreparationDays } from './PreparationDays';
import { LocTimesAndMetButtons } from './LocTimesAndMetButtons';

export const LocationTimesAndMethod = ({ ecommerce, goNextStage }) => {
    let history = useHistory();
    const user = useAuthStore((state) => state.authData.user);
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const store = useonboardingStore((state) => state.onboardingData.store);
    const [singleLocation, setSingleLocation] = useState({});
    const [singleLocationIndex, setSingleLocationIndex] = useState(null);
    const [allLocations, setAllLocations] = useState(store?.currentlocations);
    const [preparationTime, setPreparationTime] = useState(0);
    const [isPersonalizedTime, setIsPersonalizedTime] = useState("");
    const [weekDays, setWeekDays] = useState([{
        day: 0,
        open: false,
        openingHour: 0,
        openingMinute: 0,
        closingHour: 0,
        closingMinute: 0
    },
    {
        day: 1,
        open: false,
        openingHour: 0,
        openingMinute: 0,
        closingHour: 0,
        closingMinute: 0
    },
    {
        day: 2,
        open: false,
        openingHour: 0,
        openingMinute: 0,
        closingHour: 0,
        closingMinute: 0
    },
    {
        day: 3,
        open: false,
        openingHour: 0,
        openingMinute: 0,
        closingHour: 0,
        closingMinute: 0
    },
    {
        day: 4,
        open: false,
        openingHour: 0,
        openingMinute: 0,
        closingHour: 0,
        closingMinute: 0
    },
    {
        day: 5,
        open: false,
        openingHour: 0,
        openingMinute: 0,
        closingHour: 0,
        closingMinute: 0
    },
    {
        day: 6,
        open: false,
        openingHour: 0,
        openingMinute: 0,
        closingHour: 0,
        closingMinute: 0
    },]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [timeErrors, setTimeErrors] = useState([]);
    const [applyConfigToAll, setApplyConfigToAll] = useState(false);
    const [isAutomatic, setIsAutomatic] = useState(false);
    const [allCompleted, setAllCompleted] = useState(false);
    const nextShopifyRouteParam = user?.insidePanel ? "panel/location-list" : "location-list";

    useEffect(() => {
        let pathArray = location.pathname.split("/");
        let selectedId = pathArray[pathArray.length - 1];
        let list = deepCopy(store.currentlocations);
        let singleLocSelected = list.find(loc => loc.locationId === selectedId);
        let singleLocIndex = list.findIndex(loc => loc.locationId === selectedId);
        setSingleLocation(singleLocSelected);
        setSingleLocationIndex(singleLocIndex);
        if (singleLocSelected?.preparationTime > 4320) {
            let minutesToDays = singleLocSelected?.preparationTime / 1440;
            setPreparationTime(minutesToDays);
            setIsPersonalizedTime(true)
        } else {
            setPreparationTime(singleLocSelected?.preparationTime);
            setIsPersonalizedTime(false)
        }
        setWeekDays(singleLocSelected?.timeSchedule);
        setIsAutomatic(singleLocSelected?.isAutomaticCreation);
    }, [])

    useEffect(() => {
        checkForEnablingButton(weekDays, setAllCompleted, preparationTime)
    }, [weekDays, preparationTime])

    useEffect(() => {
        if (!user.insidePanel) {
            amplitudeTrack("shopify-delivery_land", user.storeId, trackedEvents["shopify-delivery_land"]);
        }
    }, [])

    const setTimeConfiguration = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        let finalPreparationTime = preparationTime;
        if (isPersonalizedTime) {
            finalPreparationTime = preparationTime * 1440
        }
        if (!user.insidePanel) {
            amplitudeTrack("shopify-delivery_next_click", user.storeId, trackedEvents["shopify-delivery_next_click"], { location_types: [singleLocation.address, isAutomatic ? "express" : "on-demand"] });
        }
        if (applyConfigToAll) {
            let modified = allLocations.map(eachloc => {
                eachloc = { ...eachloc, preparationTime: finalPreparationTime, timeSchedule: weekDays, isEveryOpenDayConfigurated: true, isAutomaticCreation: isAutomatic, manager: singleLocation.manager, phoneNumber: singleLocation.phoneNumber, notes: singleLocation.notes }
                return eachloc
            })
            try {
                const result = await saveLocationsData({ locations: modified }, user?.storeId);
                if (result && result.success) {
                    showSuccess(result.message);
                    history.push(`/${customerRouteName}/${changeShopifyName(ecommerce)}/${nextShopifyRouteParam}`)
                }
                if (result.error) {
                    showError(result.error);
                }
                setIsLoading(false);
            } catch (error) {
                showError(error);
                setIsLoading(false);
            }
        } else {
            let singleLocCopy = { ...singleLocation };
            singleLocCopy.timeSchedule = weekDays
            singleLocCopy.preparationTime = finalPreparationTime
            singleLocCopy.isAutomaticCreation = isAutomatic
            singleLocCopy.isEveryOpenDayConfigurated = true
            try {
                const result = await configSingleLocation({ location: singleLocCopy }, user?.storeId);
                if (result && result.success) {
                    showSuccess(result.message);
                    history.push(`/${customerRouteName}/${changeShopifyName(ecommerce)}/${nextShopifyRouteParam}`)
                }
                if (result.error) {
                    showError(result.error);
                }
                setIsLoading(false);
            } catch (error) {
                showError(error);
                setIsLoading(false);
            }
        }
    }
    let preparationDaysProps = { weekDays, startDate, endDate, timeErrors, setWeekDays, setTimeErrors, setStartDate, setEndDate };
    let btnsProps = { ecommerce, goNextStage, isLoading, allCompleted };
    return (
        <motion.div id="location-timeAndMethod-form" variants={fadeInAndUp} initial="hidden" animate="visible">
            <h2 className="main-subtitle mb-3 mb-xl-4">{validateFullAddress([singleLocation.address, singleLocation.city, singleLocation.province])}</h2>
            <h1 className="main-title">{singleLocation.name ? noSpacedNameShortener(singleLocation.name, 45) : `Tienda ${singleLocationIndex + 1}`}</h1>
            <h2 className="main-subtitle">En el momento que ingresa la orden y se encuentra pagada, se tomará en cuenta tu Tiempo de Preparación y luego, automáticamente se pedirá un repartidor para que entregue la compra.</h2>
            <form onSubmit={setTimeConfiguration}>
                <motion.div className='form-blocks-container' variants={user.insidePanel ? fadeInAndUpNoDelay : fadeInAndUp} initial="hidden" animate="visible">
                    <div className="preparationTime-container mb-2 mb-xl-4">
                        <PreparationMinutes timeOptions={timeOptions} actualValue={preparationTime} isPersonalizedTime={isPersonalizedTime} setIsPersonalizedTime={setIsPersonalizedTime} setPreparationTime={setPreparationTime} />
                    </div>
                    {(preparationTime !== 0) &&
                        <>
                            <PreparationDays {...preparationDaysProps} />
                            <div className="automaticSetter-container mb-2 mb-xl-3">
                                <AutomaticSetter isAutomatic={isAutomatic} setIsAutomatic={setIsAutomatic} />
                            </div>
                            <div className='configAll-setter'>
                                <InputsCheckbox
                                    id="applyConfigToAll"
                                    value={applyConfigToAll}
                                    onChangeFn={() => setApplyConfigToAll(!applyConfigToAll)}
                                    labelTxt="Guardar configuración para todas las tiendas"
                                    checked={applyConfigToAll}
                                    disabled={false}
                                />
                            </div>
                        </>
                    }
                </motion.div>
                <LocTimesAndMetButtons {...btnsProps} />
            </form>
        </motion.div>
    )
}