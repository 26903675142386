import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import CustomerRoute from './customer/customerRoute';
import axios from 'axios';
import { useAuthStore } from '../store';
import { showError } from '../services/shared/alert.service';
import { setEcommerceURL } from '../services/shared/utils.service';

import { customerRouteName } from '../data/InfoSource';

export default function AppRoute() {
    const location = useLocation();
    let history = useHistory();
    const user = useAuthStore((state) => state.authData.user);
    const [ecommerce, setEcommerce] = useState(setEcommerceURL(location));
    const removeVtexAuthMinusEcommerce = useAuthStore((state) => state.removeVtexAuthDataKeepEcommerce);
  
    axios.interceptors.request.use(
      request => {
        if(user?.token) {
          request.headers["authorization"] = `Bearer ${user.token}`
        }
        if(user?.accessToken) {
          request.headers["authorization"] = `Bearer ${user.accessToken}`
        }
        return request;
      },
      error => {
        return Promise.reject(error);
      }
    )
  
    axios.interceptors.response.use(undefined, function axiosRetryInterceptor (err) {
      if(ecommerce==="vtex" && err?.response?.status == 401) {
        removeVtexAuthMinusEcommerce();
        history.push(`/${customerRouteName}/vtex/sign-in`);
        showError('Expiró el token', 3000);
      }
      return Promise.reject(err);
    })

    return (
        <>
            <Route path={`/${customerRouteName}`} render={() => <CustomerRoute rootCustomerPath={customerRouteName} /> }/>
        </>
    )
}