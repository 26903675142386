import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { LocationItem, LocationItemError } from './LocationItem';
import { useAuthStore, useonboardingStore } from '../../../store';
import { checkConfiguration, getLocations, saveLocationsData } from '../../../services/onboarding.service';
import { showError, showSuccess } from '../../../services/shared/alert.service';
import { ButtonSmall } from '../../../components/units/Button';
import { motion } from 'framer-motion';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { changeShopifyName } from '../../../services/shared/utils.service';
import { customerRouteName, customerName } from '../../../data/InfoSource';
import { childVariants, fadeInAndUp, fadeInAndUpNoDelay, growBtnSmallDelay } from '../../../services/shared/animation.service';
import { LocationsConfigGeneralOption } from './LocationsConfigGeneralOption';

export const LocationsConfig = () => {
    const addData = useonboardingStore((state) => state.addData);
    const ecommerce = useAuthStore((state) => state.authData.user.ecommerce);
    const store = useonboardingStore((state) => state.onboardingData.store);
    const user = useAuthStore((state) => state.authData.user);
    let history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [currentlocations, setCurrentlocations] = useState(store?.currentLocations || []);
    const [activationSwitch, setActivationSwitch] = useState(store?.activeAllSwitch);
    const [allActiveLocationsConfigurated, setAllActiveLocationsConfigurated] = useState(false);
    const [btnMessage, setbtnMessage] = useState("Debes activar al menos una para continuar");
    const [showEmptyMsg, setshowEmptyMsg] = useState(true);
    const nextShopifyRouteParam = user?.insidePanel ? "panel/location-list" : "rates";

    useEffect(() => {
        if (currentlocations.length === 0) {
            updateLocations();
        } else {
            setshowEmptyMsg(false);
            setIsLoading(false);
            return
        }
    }, []);

    const updateLocations = async () => {
        try {
            setIsLoading(true)
            let records = {};
            records = await getLocations(user.storeId);
            if (records) {
                setshowEmptyMsg(false)
                checkConfiguration(records.locations, setAllActiveLocationsConfigurated, setbtnMessage);
                setCurrentlocations([...records.locations])
                addData({
                    store: {
                        currentlocations: [...records.locations],
                    },
                });
            } else {
                setshowEmptyMsg(true)
                setCurrentlocations([])
            }
            if (records.error) {
                showError(records.error);
            }
            setIsLoading(false)
        } catch (error) {
            setCurrentlocations([])
            showError(error)
            setIsLoading(false)
        }
    }

    const handleChange = (e, index) => {
        let stateCopy = currentlocations;
        let selectedObj = stateCopy.slice(index, index + 1);
        selectedObj[0].availableForDelivery = e.target.checked;
        stateCopy[index] = selectedObj[0];
        setCurrentlocations([...stateCopy]);
        checkConfiguration(currentlocations, setAllActiveLocationsConfigurated, setbtnMessage);
    }

    const handleActiveAll = (e) => {
        if (e.target.checked) {
            let stateCopy = currentlocations;
            stateCopy.forEach(loc => {
                if (loc.inCoverage) {
                    loc.availableForDelivery = true
                }
            })
            setCurrentlocations([...stateCopy]);
        } else {
            let stateCopy = currentlocations;
            stateCopy.forEach(loc => {
                if (loc.inCoverage) {
                    loc.availableForDelivery = false
                }
            })
            setCurrentlocations([...stateCopy]);
        }
        checkConfiguration(currentlocations, setAllActiveLocationsConfigurated, setbtnMessage);
        setActivationSwitch(e.target.checked);
        addData({
            store: {
                ...store,
                activeAllSwitch: e.target.checked,
            },
        });
    }

    const saveLocationsConfig = async () => {
        try {
            setIsLoading(true);
            const result = await saveLocationsData({locations: currentlocations}, user.storeId);
            if (result && result.success) {
                showSuccess(result.message);
                history.push(`/${customerRouteName}/${changeShopifyName(ecommerce)}/${nextShopifyRouteParam}`)
            }
            if (result.error) {
                showError(result.error);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showError(error);
        }
    }
    let generalOptionsProps = { activationSwitch, handleActiveAll, ecommerce, customerRouteName, user, isLoading, updateLocations }
    return (
        <div id="locationsConfig" className="content-wrapper">
            <motion.div variants={user.insidePanel ? fadeInAndUpNoDelay : fadeInAndUp} initial="hidden" animate="visible">
                <h1 className="main-title">Tus tiendas</h1>
                <h2 className="main-subtitle">Activa y agrega información extra a tus tiendas para incluir en <span className="fw-bold">{customerName}</span>.</h2>
                <LocationsConfigGeneralOption {...generalOptionsProps} />
                {isLoading ?
                    <>
                        <Skeleton count={1} height={50} style={{ marginBottom: "8px" }} />
                        <Skeleton count={1} height={50} style={{ marginBottom: "8px" }} />
                        <Skeleton count={1} height={50} style={{ marginBottom: "8px" }} />
                        <Skeleton count={1} height={50} style={{ marginBottom: "8px" }} />
                    </>
                    :
                    <div className="content-locations-list">
                        {showEmptyMsg && <div className="empty-msg"><p>Aún no hay tiendas disponibles</p></div>}
                        {currentlocations.map((location, index) => (
                            <motion.div variants={childVariants} key={index}>
                                {location.inCoverage ?
                                    <LocationItem
                                        ecommerce={ecommerce}
                                        index={index + 1}
                                        id={location.locationId}
                                        checked={location.availableForDelivery}
                                        handleChange={(e) => handleChange(e, index)}
                                        value={location.availableForDelivery}
                                        insidePanel={user?.insidePanel}
                                        {...location}
                                    />
                                    :
                                    <LocationItemError
                                        ecommerce={ecommerce}
                                        index={index + 1}
                                        id={location.locationId}
                                        insidePanel={user?.insidePanel}
                                        {...location}
                                    />
                                }
                            </motion.div>
                        ))}
                    </div>
                }
            </motion.div>
            <motion.div className="bottomPage" variants={growBtnSmallDelay} initial="hidden" animate="visible">
                <ButtonSmall
                    ecommerce={ecommerce}
                    type="button"
                    onClickFn={saveLocationsConfig}
                    btnTxt={allActiveLocationsConfigurated ? "Guardar" : btnMessage}
                    showSpinner={false}
                    isDisabled={allActiveLocationsConfigurated ? false : true}
                />
            </motion.div>
        </div>
    )
}