import React, { useEffect } from 'react';
import { ConfigurationsForm } from '../../components/onboarding/settings/ConfigurationsForm';
import { OnboardingStepper } from '../../components/onboarding/OnboardingStepper';
import { useAuthStore } from '../../store';
import { fadeInAndRight } from '../../services/shared/animation.service';
import {motion} from 'framer-motion';
import { amplitudeTrack } from '../../services/onboarding.service';
import { trackedEvents } from '../../data/InfoSource';

export const Configurations = () => {
    const user = useAuthStore((state) => state.authData.user);

    useEffect(() => {
        amplitudeTrack("shopify-package_land", user.storeId, trackedEvents["shopify-package_land"]);
      }, [])

    return (
        <div className="container-fluid" id="configurations">
            <div className="row">
                <div className="col-3 p-0">
                    <motion.div variants={fadeInAndRight} initial="hidden" animate="visible">
                        <OnboardingStepper step={3} ecommerce={user.ecommerce} />
                    </motion.div>
                </div>
                <div className="col-9 content-wrapper">
                    <ConfigurationsForm ecommerce={user.ecommerce} />
                </div>
            </div>
        </div>
    )
}
